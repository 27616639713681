import React, { useState } from "react";
import '../Components/cat_btn.css'


const CatBtn=(props)=> {
  const [value,setValue]=useState(props.value);  

  return (
    <button className={props.active===value?"cat_btn active":"cat_btn"}  onClick={() => props.getValueFromCat(value)}>{props.text}</button>  
  );
}

export default CatBtn;  
