import Carousel from 'react-bootstrap/Carousel';
import '../Components/carousel.css'
import school from '../Assets/Images/13113.jpeg'
import onlinelesson from '../Assets/Images/112233.jpeg'
import books from '../Assets/Images/223344.jpeg'


const HomeCarousel = (props) => {

  return(

    <Carousel className='carousel'>
      
      <Carousel.Item className='carousel-item'>
        <img
          className="carousel-img"
          src={onlinelesson}
          alt="First slide"          
        />
      </Carousel.Item>

      <Carousel.Item className='carousel-item'> 
        <img
          className="carousel-img"
          src={school}
          alt="Second slide"         
        />        
      </Carousel.Item>

      <Carousel.Item className='carousel-item'>
        <img
          className="carousel-img"
          src={books}
          alt="Third slide"          
        />        
      </Carousel.Item>

    </Carousel>

  );
};
export default HomeCarousel;
