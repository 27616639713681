
import React, { useEffect } from "react";
import '../Components/box.css'

const SummaryLine = (props) =>{

  useEffect(()=>{   
    props.updateTotal(props.price)
    
  })
//-------------------------------------------------------------------------------------------
    
  return (
           
    <div class="item-box" style={{background:props.bgcolor}}>                                                      
      <div className="d-flex flex-wrap align-items-center justify-content-around p-2" > 

        <div class="d-flex flex-column align-items-center px-3 py-2 py-lg-0">            
          <span><b>{props.index+1}</b></span>
        </div>

        <div class="d-flex flex-column align-items-center gap-2 px-3 py-2 py-lg-0">
            <span className="teacher">{props.name}</span>                       
        </div>
        
        <div class="d-flex flex-column align-items-center gap-2 px-3 py-2 py-lg-0">
            <span className="price">{props.amount}</span>               
        </div>

        <div class="d-flex flex-column align-items-center gap-2 px-3 py-2 py-lg-0">
            <span className="price">{props.price}</span>               
        </div>
        
      </div>                                                  
    </div>                                
  );
};

export default SummaryLine;
