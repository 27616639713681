
import React from "react";
import GrpCourseBox from "./grp_course_box";
import PrvtCourseBox from "./private_course_box";

const CourseBox = (props) =>{

  return (
      <>
        {props.private?
          <PrvtCourseBox 
            search={props.search} 
            bgcolor={props.bgcolor} 
            teacher={props.teacher} 
            rating={props.rating} 
            ratings={props.ratings}
            subject={props.subject} 
            photo={props.photo}
            reg={props.reg}
            updateCourseStatus={props.updateCourseStatus}
            index={props.index}
            stdId={props.stdId}
            user={props.user}
            code={props.code}
            xcode={props.xcode} 
            tchId={props.tchId}           
            price={props.price}
            status={props.status} 
            syllabus={props.syllabus}           
          >
            <div>{props.children}</div>
          </PrvtCourseBox>
        :
          <GrpCourseBox 
            search={props.search} 
            bgcolor={props.bgcolor} 
            teacher={props.teacher} 
            rating={props.rating} 
            ratings={props.ratings}
            subject={props.subject} 
            date={props.date}
            price={props.price} 
            photo={props.photo} 
            code={props.code}
            xcode={props.xcode}
            reg={props.reg}
            updateCourseStatus={props.updateCourseStatus}
            index={props.index}
            stdId={props.stdId}
            schedule={props.schedule}
            refresh={props.refresh}
            user={props.user}  
            status={props.status} 
            syllabus={props.syllabus}   
          >
             <div>{props.children}</div>
          </GrpCourseBox>
        }                                    
      </>                                    
  );
};



export default CourseBox;
