import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right:95,
    top: 2,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const RequestsBadge=(props)=> {

  return (
    
      <StyledBadge badgeContent={props.count} color="secondary">
       <span>אישור מפגשים</span>
      </StyledBadge>
    
  );
}

export default RequestsBadge; 
