import React, { useState,useEffect } from "react";


const SelectLessonsNum=(props)=>{ 
                           
    const [num,setNum]=useState(0);
    const lessonsNum=["1","2","3","4"];
       
    const handleChange = (event) => {
        setNum(event.target.value)
        props.setLessonsNum(event.target.value)
    }

    useEffect(()=>{
       
        setNum(props.num)
       
    },[props.num])
//------------------------------------------------------------------------------------------- 

    return (  
        <div>                
            <div className="label-100-wrap">
                <label class="form-label label-100" for="num">מס' שיעורים</label>              
                <div>
                    <select 
                        className={`form-control ${props.error? 'invalid' : ''}`}  
                        name="num" style={{width:"125px"}} 
                        value={num} 
                        onChange={handleChange}
                        >
                        {
                            lessonsNum.slice(0,props.maxNum).map((num,index)=>(
                                <option value={index+1}>{num}</option>
                            ))

                        }                                         
                    </select> 
                </div>
                <p className="error">{props.error}</p>
            </div>                                                                                                                    
        </div>        
    )  
  } 
  
export default SelectLessonsNum;  
