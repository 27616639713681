import React, { useState } from "react";
import { updateUserPassword } from "./db"; 
import '../Components/profile.css';
import * as Yup from 'yup';
import '../Components/forms.css'


const loginSchema = Yup.object().shape({
  
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()    
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required'),
});


const ChangePassword=(props)=> {
  const [errors, setErrors] = useState({});
  const [passwordDtls, setPasswordDtls] = useState({  
    newPassword:'', 
    confirmPassword:'',
  });
//-------------------------------------------------------------------------------------------  

  const handleChange = e => {
      const { name, value } = e.target;
      setPasswordDtls(prevState => ({
          ...prevState,
          [name]: value
      }));
  };
//-------------------------------------------------------------------------------------------  
                
  const handleSubmit = async (event) => {
        
    event.preventDefault();
    
    try {
        await loginSchema.validate(passwordDtls, { abortEarly: false });           
          
        const code = await updateUserPassword(
          passwordDtls.newPassword, 
          props.userId                         
        )
         
        if( code > 0 ){
          alert("שינוי סיסמה עבר בהצלחה!!")          
        }                  
        else 
          alert("אירעה שגיאה בעת שינוי הסיסמה!!")

        setPasswordDtls({newPassword:'',confirmPassword:''});
        setErrors({});                                                                           
    }
    catch (err) {
    
      setErrors(err.inner.reduce((acc, { path, message }) => {
        acc[path] = message;
        return acc;
      }, {}));
    }        
  } 
//------------------------------------------------------------------------------------------- 

  return (
    
    <form onSubmit={handleSubmit}>
      <div className="d-flex flex-column profile-gap p-4 pt-5">  

        <div  className="label-100-wrap">                
          <label className="form-label label-100" for="new_pass">סיסמה חדשה</label>
          <div>
            <input 
              type="password" 
              className={`form-control ${errors.newPassword? 'invalid' : ''}`}
              name="newPassword" 
              value={passwordDtls.newPassword} 
              onChange={handleChange} placeholder="סיסמה"
            /> 
            {errors.newPassword && <p className="error">{errors.newPassword}</p>}
        </div>   
        </div>  

        <div  className="label-100-wrap">                
          <label className="form-label label-100" for="con_pass">אשר סיסמה</label>
          <div>
            <input 
              type="password" 
              className={`form-control ${errors.confirmPassword? 'invalid' : ''}`}                
              name="confirmPassword" 
              value={passwordDtls.confirmPassword} 
              onChange={handleChange} 
              placeholder="אימות סיסמה"
            />
            {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>} 
          </div>   
        </div>

        <div>
          <button type="submit" class="btn btn-outline-secondary btn-sm daily-prog-btn" style={{width:"100%"}}>
            החלף סיסמה
          </button>
        </div>                   
                                                                                                                                                          
      </div> 
    </form>     
  );
}

export default ChangePassword;
