import { Navbar, Nav} from 'react-bootstrap';
import { Outlet, Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import { FaSearch } from 'react-icons/fa';
import HomeIcon from '@mui/icons-material/Home';
import '../Layouts/header.css'
import LoginIcon from '@mui/icons-material/Login';

const Header = (props) => {
  return (
    <>
      <Navbar className='position-relative' collapseOnSelect expand='md' bg='dark' variant='dark'>
        
          <Navbar.Toggle className='ms-4' aria-controls='responsive-navbar-nav'/>
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className="nav mt-4 mt-md-2 mb-4 mb-md-2 ms-4 ms-md-5 align-items-start align-items-md-center">
                <Avatar src={props.avatar} />
                <Link className='nav-item' to="/"><HomeIcon/></Link>                        
                <Link className='nav-item' to="/about">אודות</Link> 
                <Link className='nav-item' to="/contact">צור קשר</Link>  
                <Link className='nav-item' to="/search"><FaSearch size={20}/></Link>                                           
            </Nav>            
          </Navbar.Collapse>
          <Link className='nav-item position-absolute login-pos' to="/login" onClick={()=>props.login()}><LoginIcon/> <b>כניסה</b></Link>         
      </Navbar>

      <Outlet />

    </>
  )
};

export default Header;