import React, { useEffect, useState } from "react";
import avatar1 from "../Assets/Images/img_avatar1.png"
import { FcApproval } from "react-icons/fc";
import { MdCancel } from "react-icons/md";
import BasicRating from "./rating";
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const TchProfileCard=(props)=> {
  
  const [tchDtls,setTchDtls]=useState({
    f_name:'',
    l_name:'',
    email:'',
    mobile:'',
    photo:null,
    t_rating:null,
    t_counter:null,
    s_rating:null,
    s_counter:null,
    m_confirmed:false,
    e_confirmed:false
  });
  
  useEffect(()=>{

    const fetchProfilePhoto = async () =>{

      try {
        const response = await axios.get(`${baseUrl}/get_tch_profile_dtls.php`,{
          params: {                        
            userId:props.userId                                                                      
          }
        })
        
        if( response.data.id > 0 ){         
          setTchDtls({
            f_name:response.data.f_name,
            l_name:response.data.l_name,
            email:response.data.email,
            mobile:response.data.mobile,
            photo:response.data.photo,
            t_rating:response.data.t_rating,
            t_counter:response.data.t_counter,
            s_rating:response.data.s_rating,
            s_counter:response.data.s_counter,                       
            m_confirmed:response.data.m_confirmed,
            e_confirmed:response.data.e_confirmed
          })

        }
        else{
          setTchDtls({
            f_name:'',
            l_name:'',
            email:'',
            mobile:'',
            photo:null,
            t_rating:null,
            t_counter:null,
            s_rating:null,
            s_counter:null,
            m_confirmed:false,
            e_confirmed:false
          })
        }
         
      } 
      catch (error) {

        alert(error) ;
       
      } 

    }

    fetchProfilePhoto();

  },[])
//-------------------------------------------------------------------------------------------

  return (

    <div className="d-flex flex-column gap-4"  style={{width:"100%"}}> 
      
      <div class="d-flex justify-content-center">
          <img  className="rounded " src={tchDtls.photo===null?avatar1:tchDtls.photo} style={{width:"220px",height:"auto"}}></img>
      </div>

      <div class="d-flex justify-content-center">
        <div className="d-flex flex-column align-items-center">
          <span className="teacher-100">{tchDtls.f_name + ' ' + tchDtls.l_name}</span>                             
          <span dir="ltr">
            <BasicRating 
              value={tchDtls.s_rating!=null?tchDtls.s_rating:tchDtls.t_rating} 
              ratings={tchDtls.s_counter!=null?tchDtls.s_counter:tchDtls.t_counter} 
              readOnly={true} 
            />
          </span>                                                        
        </div>
      </div>
                                      
      <div className="d-flex gap-2 align-items-center mt-3">

            <div  className="label-100-wrap" style={{width:"100%"}}>                
              <label className="form-label label-100" for="email">דואר אלקטרוני</label>
              <div dir="ltr">
                  <input type="email" class="form-control" id="email" name="email" value={tchDtls.email} readOnly></input>               
              </div>   
            </div>

            <div>
              {tchDtls.e_confirmed==1?
                <FcApproval size={22}/>
                :
                <MdCancel color="red" size={22}/>
              } 
            </div>

      </div> 

      <div className="d-flex gap-2 align-items-center">

        <div  className="label-100-wrap" style={{width:"100%"}}>                
          <label className="form-label label-100" for="mobile">נייד</label>
          <div dir="ltr">
              <input type="text" class="form-control" id="mobile" name="mobile" value={tchDtls.mobile}  readOnly></input>               
          </div>   
        </div>

        <div>
          {tchDtls.m_confirmed==1?
            <FcApproval size={22}/>
            :
            <MdCancel color="red" size={22}/>
          } 
        </div>
            
      </div>                                                                                                                                               
    </div>        
  );
}

export default TchProfileCard;
