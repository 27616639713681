import { useState,useEffect } from "react";
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const SelectTeacher=(props)=>{
    
  const [teachers,setTeachers]=useState([])
  const [tch,setTch]=useState(-1)

  useEffect(()=>{

    const fetchTeachers = async () => {
     
      try 
      {
        const response = await axios.get(`${baseUrl}/teachers_by_center.php`,{
          params: {
             centerId:props.centerId                 
          }
        })
        
        if(Array.isArray(response.data) && response.data.length){
          setTeachers(response.data)
          const teacherExists = response.data.some(teacher => teacher.id === props.tchId);
          if (!teacherExists || props.tchId === 0) {
              setTch(response.data[0].id);
              props.setTeacher(response.data[0].id, response.data[0].teacher);
          } else {
              setTch(props.tchId);
              /*const selectedTeacher = response.data.find(teacher => teacher.id === props.tchId);
              if (selectedTeacher) {
                  props.setTeacher(selectedTeacher.id, selectedTeacher.teacher);
              }*/
          }
        } else {            
          setTeachers([])
        } 
      } 
      catch (error) {

        alert(error) ;
        
      }
      
    }

    fetchTeachers();
                          
  },[props.centerId, props.tchId]);
//-------------------------------------------------------------------------------------------
      
  return(

    <div>
        <select 
          class="form-select" 
          name="teachers" 
          value={tch} 
          onChange={(e) => {
            const selectedId = e.target.value;
            const selectedTeacher = teachers.find(t => t.id === selectedId);
            setTch(selectedId);
            if (selectedTeacher) {
              props.setTeacher(selectedId, selectedTeacher.teacher);
            }
          }}
        >                                           
            {
                teachers.map((t)=>(
                    <option value={t.id}>{t.teacher}</option>
            ))}                                                                                              
        </select> 
    </div>

  )}

export default SelectTeacher;
