import React, { useState,useEffect } from "react";
import LessonBox from "./lesson_box";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import LessonMenu from "./lesson_menu";

const UserLessonBox = (props) => {
  const [endLesson,setEndLesson]=useState(false)
  const [cancelled,setCancelled]=useState(false);
 
  useEffect(()=>{
   
      setEndLesson(false)
      
  },[props.refresh])
//-------------------------------------------------------------------------------------------    

  useEffect(()=>{
        
    setCancelled(props.cancelled)
  },[props.cancelled])
//-------------------------------------------------------------------------------------------    
  
  const endOfLesson = () => {
    
    setEndLesson(true)    
  }
//-------------------------------------------------------------------------------------------          

  const changeToCancelStatus = () => {

    setCancelled(true) 
  }
//-------------------------------------------------------------------------------------------          
   
  return (         
        
    <LessonBox 
      bgcolor={props.bgcolor} 
      teacher={props.teacher} 
      lessonId={props.lessonId}
      tchId={props.tchId}
      userId={props.userId}
      mode={props.mode}            
      rating={props.rating} 
      ratings={props.ratings} 
      subject={props.subject} 
      date={props.date} 
      start={props.start} 
      lessons_num={props.lessons_num} 
      end={props.end} 
      price={props.price} 
      photo={props.photo} 
      user={props.user}  
      platform={props.platform}          
      recording={props.recording}
      done={props.done}
      cancelled={cancelled}
      cal={props.cal}  
      refresh={props.refresh}
      endOfLesson={endOfLesson}
      set_show_overlay={props.set_show_overlay}
    >
      {props.user==='TCH'?                      
        <LessonMenu 
          code={props.code} 
          lessonId={props.lessonId} 
          tchId={props.tchId}
          recording={props.recording}              
          updateLessonSubject={props.updateLessonSubject}
          updateLessonDtls={props.updateLessonDtls}
          changeToCancelStatus={changeToCancelStatus}
          endLesson={endLesson}
          cancelled={cancelled}
          mode={props.mode}
          index={props.index}
        />        
        :
        null 
      }
    </LessonBox>      
  );
};

export default UserLessonBox;
