import { Navbar, Nav } from 'react-bootstrap';
import { Outlet, Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import ShoppingCartBadge from '../Components/cart_badge';
import MessageBadge from '../Components/message_badge';
import HomeIcon from '@mui/icons-material/Home';
import '../Layouts/header.css'
import { FaSearch } from 'react-icons/fa';
import LogoutIcon from '@mui/icons-material/Logout';


const StdHeader = (props) => {
        
  return (
    <>
      <Navbar className='position-relative' collapseOnSelect expand='md' bg='dark' variant='dark'>
          
          <Navbar.Toggle className='ms-3' aria-controls='responsive-navbar-nav'/>
             
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className="nav mt-4 mt-md-2 mb-4 mb-md-2 ms-4  align-items-start align-items-md-center">
                <Avatar src={props.avatar} />
                <Link className='nav-item' to="/"><HomeIcon/></Link>
                <Link className='nav-item' to="/stdcourses">קורסים שלי</Link>
                <Link className='nav-item' to="/stdclassonline">כיתה מכוונת</Link>
                <Link className='nav-item' to="/stdprofile">פרופיל</Link>
                <Link className='nav-item' to="/contact">צור קשר</Link>
                <Link className='nav-item' to="/about">אודות</Link> 
                {/*<Link className='nav-item' to="/messages"><MessageBadge/></Link> */ }
                <Link className='nav-item' to="/search"><FaSearch size={20}/></Link>                                                                 
            </Nav>
          </Navbar.Collapse> 
          <Link className='nav-item position-absolute cart-pos' to="/cart"><ShoppingCartBadge amount={props.amount}/></Link>        
          <a className='nav-item position-absolute login-pos' onClick={() => props.disconnect()}><LogoutIcon/><b> יציאה</b></a>
      </Navbar>

      <Outlet />

    </>
  )
};

export default StdHeader;