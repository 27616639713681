import React, { useEffect } from 'react';
import { IconButton } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const AddLessonToCalendar = (props) => {

  const addLessonToCalendar = async () => {
    try {
      const response = await axios.get(`${baseUrl}/add_lesson_to_calendar.php`, {
        params: {
          topic: props.topic,
          date: props.date,
          start: props.start,
          lessons_num: props.lessons_num,
          lessonId: props.lessonId,
          mode: props.mode,
          user: props.user,
          userId: props.userId
        }
      });
  
      const responseData = response.data;
  
      if (responseData.code === 0 && responseData.redirectUrl) {
        const openedChildWindow = window.open(responseData.redirectUrl, '_popup', 'width=550, height=600, left=359, top=100');
  
        if (openedChildWindow) {
          
          const checkChildWindowClosedInterval = setInterval(() => {
            if (openedChildWindow.closed) {
              props.set_show_overlay(false); 
              clearInterval(checkChildWindowClosedInterval);              
            }
          }, 1000);

          props.set_show_overlay(true);
          
        } else {
          alert("Popup blocked! Please allow popups for this site.");
        }
      } else {
        alert(responseData.message);
      }
  
    } catch (error) {
      alert(error);
    }
  };
  
  
  return (
    <div>
      <IconButton onClick={addLessonToCalendar} disabled={!props.enable}>
        <EventIcon fontSize='small' />
      </IconButton>
    </div>
  );
};

export default AddLessonToCalendar;
