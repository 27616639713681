
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA9U0XQvNdEcLXbaFHTHwBEAih3G7KBAx0",
  authDomain: "doroosonline-dd279.firebaseapp.com",
  projectId: "doroosonline-dd279",
  storageBucket: "doroosonline-dd279.appspot.com",
  messagingSenderId: "26063055442",
  appId: "1:26063055442:web:4d2699b3b12de1a926cd8b",
  measurementId: "G-4C3X198F2J"
};


const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
