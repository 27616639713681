import { useEffect, useState } from 'react';

const baseUrl = process.env.REACT_APP_BASE_URL;

const useCountdown = (targetDateInMillis) => {
  const [countDown, setCountDown] = useState(1000);

  useEffect(() => {
    
    fetch(`${baseUrl}/get_current_date_and_time.php`)
      .then(response => response.json())
      .then(data => {
        const serverDateStr = data.now;
        const serverDateMs = new Date(serverDateStr).getTime(); 
        const initialCountDown = targetDateInMillis - serverDateMs;

        setCountDown(initialCountDown);

        const interval = setInterval(() => {
          setCountDown(prevCountDown => prevCountDown - 1000 );
        },1000);

        return () => clearInterval(interval);
      })
      .catch(error => console.error("Could not fetch server time:", error));

  }, [targetDateInMillis]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export { useCountdown };
