import React from 'react';
import { useCountdown } from './use_count_down';
import { GiAlarmClock } from 'react-icons/gi';

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div dir='ltr'>            
      {days} : {hours} : {minutes} : {seconds}                           
    </div>
  );
};

const CountdownTimer = (props) => {
  const [days, hours, minutes, seconds] = useCountdown(props.targetDate);

  if (days + hours + minutes + seconds <= 0) {
    props.countdownStopped()
  } 
  else {
    return (
      <button type="button" class="btn btn-warning btn-sm" style={{borderRadius:"20px",width:"130px"}} disabled>
          <div className="position-relative">                                                
              <span className="float-start ps-1"><ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds}/></span>
              <span className="position-absolute" style={{top:"-2px",left:"-3px"}}><GiAlarmClock size={20}/></span>                                                                                                   
          </div>
      </button>                                          
    );
  }
};

export default CountdownTimer;
