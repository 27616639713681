import { useEffect, useState} from "react";

const CourseMode = (props) => {
    
    const [courseMode,setCourseMode]=useState({
        grp:true,
        prvt:false
    }); 
//-------------------------------------------------------------------------------------------

    useEffect(()=>{

        if(props.mode==='GROUP')
            setCourseMode({['grp']:true,['prvt']:false})
        else
            setCourseMode({['grp']:false,['prvt']:true})

    },[props.mode])
//-------------------------------------------------------------------------------------------
                   
    return(
                    
        <div class="d-flex flex-wrap align-items-center gap-4">


            {props.editing? 
                <>               
                    <div class="form-check" > 
                        <input class="form-check-input" type="radio" name="user" id="group"  checked={courseMode.grp}  disabled></input>
                        <label class="form-check-label" for="group">שיעורים בקבוצות</label>                                
                    </div>

                    <div class="form-check" > 
                        <input class="form-check-input" type="radio" name="user" id="private" checked={courseMode.prvt} disabled></input>
                        <label class="form-check-label" for="private">שיעורים פרטיים</label>                                
                    </div> 
                </>
                :
                <>               
                    <div class="form-check" > 
                        <input class="form-check-input" type="radio" name="user" id="group" checked={courseMode.grp} onClick={() =>{                   
                            setCourseMode({['grp']:true,['prvt']:false});
                            props.setCourseMode("GROUP")                   
                        }}></input>
                        <label class="form-check-label" for="group">שיעורים בקבוצות</label>                                
                    </div>

                    <div class="form-check" > 
                        <input class="form-check-input" type="radio" name="user"  id="private" checked={courseMode.prvt} onClick={() =>{                    
                            setCourseMode({['grp']:false,['prvt']:true});
                            props.setCourseMode("PRIVATE")          
                        }}></input>
                        <label class="form-check-label" for="private">שיעורים פרטיים</label>                                
                    </div> 
                </>
            }
                                                
        </div>                                   
    )};
  
export default CourseMode;
