import React, {useState,useEffect} from "react";
import PageTitle from "../Components/page_title";
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import SearchByDates from "../Components/search_by_date";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import CourseBox from '../Components/course_box';
import ReactLoading from 'react-loading';
import axios from 'axios';
import Divider from '@mui/material/Divider';

const baseUrl = process.env.REACT_APP_BASE_URL; 
const win=4;

const StdCourses = (props) => {
  const [page, setPage] = useState(1);
  const [schedule,setSchedule]=useState(false);
  const [courses,setCourses]=useState([]);
  const [status,setStatus]=useState("ACTIVE");
  const [fire,setFire]=useState(false);
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    setScreenHeight(window.innerHeight-200);
  }, []);
//-------------------------------------------------------------------------------------------
    
  useEffect(()=>{

    const fetchCourses = async () => {
      try {

        setFire(true)

        const response = await axios.get(`${baseUrl}/std_courses.php`,{
          params: {                        
            stdId:props.userId,
            filter:status                                                                      
          }
      })
        if(Array.isArray(response.data) && response.data.length){
          setCourses(response.data)
          setSchedule(prev=>prev=!prev)
        }
        else{
          setCourses([])
        }
        setFire(false)  
      } 
      catch (error) {

        alert(error) ;
        
      }

      setPage(1)
      goToTop()
      
    }

    if(status!=="UNDEFINED")
      fetchCourses();
        
  },[status]);
//-------------------------------------------------------------------------------------------

  const fetchCoursesByDates = async (sDate,eDate) => {
    try {

      setFire(true)

      const response = await axios.get(`${baseUrl}/std_courses_by_dates.php`,{
        params: {                        
          stdId:props.userId, 
          sDate:sDate,
          eDate:eDate,                                                      
        }
      })

      if(Array.isArray(response.data) && response.data.length){
        setCourses(response.data)
        setSchedule(prev=>prev=!prev)
      }
      else{
        setCourses([])
      }
      
      setStatus("UNDEFINED")  
      setFire(false)
    } 
    catch (error) {

      alert(error) ;
      
    }

    setPage(1)
    goToTop()
    
  }
//-------------------------------------------------------------------------------------------

  const goToTop = () => {
    window.scrollTo({
        top:0,
        behavior: 'auto',
    })
  }
//-------------------------------------------------------------------------------------------
  const handleChangePage = (_,page)=> {
        
    if (page !== null){
      setPage(page);
      setSchedule(prev=>prev=!prev)
      goToTop()
    }
  
  }
//-------------------------------------------------------------------------------------------

  return (
    
    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>
                      
        <div className="mt-4">
          <PageTitle text="קורסים שלי"/>
        </div>
        
        <div className="mt-5 ms-2">
          <SearchByDates setDateRange={fetchCoursesByDates}/>
        </div>

        <div className="mt-4">          
          <Divider></Divider>
        </div>

        <div className="d-flex gap-3 mt-4">
          <Button
            variant="contained"
            color="secondary"
            size="small"
            disabled={status === "ACTIVE"}
            onClick={() => setStatus("ACTIVE")}
          >
            קורסים פעילים
          </Button>

          <Button
            variant="contained"
            color="secondary"
            size="small"
            disabled={status === "ARCHIVED"}
            onClick={() => setStatus("ARCHIVED")}
          >
            ארכיון
          </Button>

        </div>
        
        <div className="d-flex flex-column position-relative gap-3 mt-2">

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x" style={{zIndex: "1",top:"300",left:'300'}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          }

          {courses.slice((page-1)*win,page*win).map((c,index)=>(                                                                                              
            <div>                                            
                <CourseBox 
                  bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"} 
                  teacher={c.teacher} 
                  rating={c.rating} 
                  ratings={c.counter} 
                  subject={c.subject} 
                  date={c.date} 
                  photo={c.photo} 
                  private={c.mode==="PRIVATE"?true:false}
                  xcode={c.gcode!==null?c.gcode:c.pcode}
                  code={c.code}
                  stdId={props.userId}
                  tchId={c.tchId} 
                  syllabus={c.syllabus} 
                  price={c.price}             
                  schedule={schedule}
                  user={props.user}
                  status={status}                  
                />                                                        
            </div>
          ))}
                             
        </div>
        
        <div  class="d-flex justify-content-end mt-4" dir="ltr">                     
          <Pagination count={Math.ceil(courses.length/win)} variant="outlined" shape="rounded" color="primary" page={page} onChange={handleChangePage}/>         
        </div>

    </div>
    
  );
};

export default StdCourses;

