import React, { useState } from "react";
import './popup_modals.css';
import axios from 'axios';
import SelectDocument from "./select_document";
import SelectInstituteByTch from "./select_institute_by_tch";
import UploadFile from "./uploadfile";

const baseUrl = process.env.REACT_APP_BASE_URL;

const UploadDocs = (props) => { 
    
    const [refresh,setRefresh]=useState(false);    
    const [empty1,setEmpty1]=useState(false);
    const [empty2,setEmpty2]=useState(false);                                                                 
    const [schId,setSchId]=useState(0);
   
    
    const [docDtls, setDocDtls] = useState({        
        id:0,
        name:''  
    });
   
//-------------------------------------------------------------------------------------------  
   
    const isEmpty1 = (flag) => {                
        setEmpty1(flag)
    } 
//-------------------------------------------------------------------------------------------      

    const isEmpty2 = (flag) => {                
        setEmpty2(flag)
    } 
//-------------------------------------------------------------------------------------------      

    const getSchId = (id) =>{        
        setSchId(id)
        props.setSchId(id)
    }
//-------------------------------------------------------------------------------------------      
    
    const getDocument=(id,name)=>{

        setDocDtls(prev=>({
            ...prev,
            ['id']:id,
            ['name']:name
        }))
        
    }
//-------------------------------------------------------------------------------------------

    const addNewLine = async (file) => {
        try {
            const response = await axios.get(`${baseUrl}/pair_user_doc.php`, {
                params: {
                    userId: props.userId,
                    user: props.user,
                    schId: schId,
                    doc: docDtls.id,
                    file: file,
                }
            });
        
            if (response.data.status === 'success' && response.data.id > 0) {
                const doc = {
                    id: response.data.id,
                    name: docDtls.name,
                    comment: '',
                    status: 'NO_RESPONSE',
                    file: file
                };
                props.add(doc);
                setRefresh(prev => !prev);                                                                   
            } else if (response.data.status === 'error') {
                throw new Error(response.data.message || 'Error occurred during document upload');
            } else {
                throw new Error("An unexpected error occurred. Please try again later.");
            }
        } catch (error) {
            
            alert('Error: ' + error.message);
        }
    };
//-------------------------------------------------------------------------------------------
        
    return (  
                                                  
        <div className="d-flex flex-wrap gap-4 align-items-center"> 

            {props.user==='TCH'?
                <div className="label-100-wrap">
                    <label class="form-label label-100" for="institutes">מעסיק</label>              
                    <div id="institutes">
                        <SelectInstituteByTch 
                            tchId={props.userId} 
                            setSchId={getSchId} 
                            isEmpty={isEmpty2} 
                        />                        
                    </div>
                </div> 
                :null
            }
            
            <div className="label-100-wrap">
                <label class="form-label label-100" for="docs">מסמך</label>              
                <div id="docs">
                    <SelectDocument 
                        setDocument={getDocument} 
                        userId={props.userId} 
                        user={props.user} 
                        schId={schId} 
                        refresh1={props.refresh} 
                        refresh2={refresh} 
                        isEmpty={isEmpty1} 
                    />
                </div>
            </div>

            <div>
                <UploadFile 
                    addNewLine={addNewLine} 
                    count={props.count} 
                    empty={empty1|empty2} 
                    isLoding={props.isLoding}
                />
            </div>                                                                                          
            
        </div> 
                                
    )  
  } 
  
export default UploadDocs;
