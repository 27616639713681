import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import Button from '@mui/material/Button';
import './popup_modals.css';
import { IoMdSettings } from "react-icons/io";
import '../Components/forms.css';

const TermsAndConditionsModal = (props) => {
    const [show, setShow] = useState(false);

    // Open the modal
    const openModal = () => {
        setShow(true);
    };

    // Close the modal
    const closeModal = () => {
        setShow(false);
    };

    return (
        <div dir="ltr">
             <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    openModal();
                }}
                style={{ textDecoration: "none", cursor: "pointer" }}
            >
                אני מסכים לתנאים וההגבלות
            </a>

            <Modal
                show={show}
                onHide={closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px', height: '60px' }}>
                    <div className="d-flex gap-2 align-items-center">
                        <div><IoMdSettings size={30} /></div>
                        <div className="mt-1"><h4>תנאים והגבלות</h4></div>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <div className="container" dir="ltr">
                        <iframe
                            src="https://firebasestorage.googleapis.com/v0/b/doroosonline-dd279.appspot.com/o/html%2Fterms.html?alt=media&token=1a078a70-333f-4435-a38a-ef955754d47d" 
                            title="Terms and Conditions"
                            width="100%"
                            height="400px"
                            style={{ border: 'none' }}
                        />                        
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div className="d-flex gap-3 me-3 mb-2">
                        <Button variant="contained" color="error" size="small" onClick={closeModal}>סגור</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default TermsAndConditionsModal;
