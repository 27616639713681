import React, { useState,useEffect } from "react";
import { Modal,Form} from 'react-bootstrap'; 
import Button from '@mui/material/Button';
import "react-datepicker/dist/react-datepicker.css";
import './popup_modals.css';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { updateLessonSubject } from "./db";

const UpdateLessonSubject=(props)=>{ 
     
    const [show,setShow]=useState(false);
    const [validated, setValidated] = useState(false);   
    const [subject,setSubject] = useState("");     
        
//-------------------------------------------------------------------------------------------

    useEffect(()=>{

        if(!props.link)
            setShow(true)

    },[])
//------------------------------------------------------------------------------------------- 

    const handleChange = event => {
        setSubject(event.target.value)        
    }
//------------------------------------------------------------------------------------------- 

    const handleSubmit = async (event) => {
        
        const form = event.currentTarget
                        
        event.preventDefault()
        event.stopPropagation()
                     
        if (form.checkValidity()){
                        
            try{
                
                const response= await updateLessonSubject(subject,props.lessonId,props.mode)
           
                alert(response.message)

                props.updateLessonSubject(subject,props.index)                               
                closeSubjectBox()
                
            }   catch (error)   {
                alert(error.message)
            }
                                                                        
        }
        else
            setValidated(true)        
    }
//------------------------------------------------------------------------------------------- 

    const openSubjectBox = () => { 
        setShow(true);
    }
//------------------------------------------------------------------------------------------- 

    const closeSubjectBox = () => { 
          setShow(false);
          if(!props.link)
              props.resetMenu(0);
    }
//------------------------------------------------------------------------------------------- 

    return (  
        <div>        
             {props.link?<button type="button" class="btn btn-success btn-sm"  style={{width:"100px",borderRadius:"5px"}} onClick={openSubjectBox}>עדכון נושא שיעור</button>:null}                         
            <Modal 
              show={show} 
              onHide={closeSubjectBox} 
              size="md"
              aria-labelledby="contained-modal-title-vcenter"            
             >  

                <Modal.Header closeButton>
                    <div className="d-flex gap-2 align-items-center">
                        <div><FaChalkboardTeacher size={30} /></div>
                        <div className="mt-1"><h4>עדכון נושא שיעור</h4></div>
                    </div>                                              
                </Modal.Header>  
                
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body> 
                                                                                           
                        <div className="label-100-wrap mt-3">                                           
                            <label className="form-label label-100" for="L-zoom">נושא השיעור</label>                                                                                           
                            <div>                    
                                <textarea class="form-control" id="L-zoom" name="zoom" rows="3" value={subject} onChange={handleChange} required></textarea>        
                            </div>   
                        </div> 
                                              
                    </Modal.Body>  

                    <Modal.Footer>
                        <div className="d-flex gap-3">                         
                            <Button variant="contained" color="error"   size="small" onClick={closeSubjectBox}>ביטול</Button>  
                            <Button variant="contained" color="success" size="small" type="submit">שמירה</Button>                         
                        </div>
                    </Modal.Footer> 

                </Form>        
            </Modal> 
        </div>        
    )  
  } 
  
export default UpdateLessonSubject;  
