import React, { useState,useEffect } from "react";
import PayPalLogo from '../Assets/Images/paypal.png'
import VisaLogo from '../Assets/Images/visa.png'
import './payment_method.css'

const PaymentMethod=(props)=>{ 
    const [paymentMethod,setPaymentMethod] = useState({        
        visa:true,
        paypal:false
    });

    useEffect(() => {
        if(props.total===0){
            setPaymentMethod({
                visa:true,
                paypal:false
            })
            props.setMethod("visa")
        }
    },[props.total])

    return ( 
        <div>                            
                                                        
            <div class="d-flex flex-column gap-2">
                
                <span className="mb-2" style={{color:"gray",fontWeight:"bold"}}>בחר אופן תשלום</span>
                
                <div className=" border py-2 px-3 rounded">
                    <div class="form-check d-flex align-items-center gap-3"> 
                        
                            <input class="form-check-input" type="radio"  id="visa" disabled={props.total===0} checked={paymentMethod.visa} onClick={() =>{                                
                                setPaymentMethod(({['visa']:true,['paypal']:false})); 
                                props.setMethod("visa");                                                    
                            }}></input>
                            <label class="form-check-label m-label" for="visa" style={{width:"40%"}}>
                                תשלום בכרטיס אשראי                               
                            </label>
                            <span style={{width:"40%"}}><img src={VisaLogo} className="credit-image" /></span>                          
                    </div>
                </div>

                <div className=" border py-2 px-3 rounded">
                    <div class="form-check d-flex align-items-center gap-3">                                                                 
                        <input class="form-check-input" type="radio"  id="paypal" disabled={props.total===0} checked={paymentMethod.paypal} onClick={() =>{
                            setPaymentMethod(({['visa']:false,['paypal']:true}));
                            props.setMethod("paypal");                            
                        }}></input>  
                        <label class="form-check-label m-label" for="paypal" style={{width:"40%"}}>
                            תשלום באמצעות PayPal                           
                        </label> 
                        <span style={{width:"40%"}}><img src={PayPalLogo} className="paypal-image"/></span>                            
                    </div>
                </div>
                
            </div> 

        </div>
       
    )  
} 
  
export default PaymentMethod;  
