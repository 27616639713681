import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { CgShoppingCart } from "react-icons/cg";

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -4,
    top: 2,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const ShoppingCartBadge=(props)=> {

  return (
    
      <StyledBadge badgeContent={props.amount} color="secondary">
        <CgShoppingCart fontSize='25px'/>
      </StyledBadge>
    
  );
}

export default ShoppingCartBadge; 
