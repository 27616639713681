import  React, {useState,useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import axios from 'axios';
import TeacherCard from "./teacher_card";
import PageTitle from "./page_title";
import Slider from "react-slick";
import CustomNextArrow from "./custom_next_arrows";
import CustomPrevArrow from "./custom_prev_arrows";
import ReactLoading from 'react-loading';

const baseUrl = process.env.REACT_APP_BASE_URL;

const SuggestedTeachers = (props) => {

  const [fire,setFire]=useState(false);
  const breakpoints={
    xxl:5,
    xl:4,
    lg:3,
    md:2,
    sm:2
  };

  const [teachers,setTeachers]=useState([]);

  const [slidesToShow,setSlidesToShow]=useState({
    bp_xxl:0,
    bp_xl:0,
    bp_lg:0,
    bp_md:0,
    bp_sm:0
  });

  const settings = {
    dots:false,
    infinite: true,
    speed: 500,
    slidesToShow:slidesToShow.bp_xxl,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow className={"slick-prev"} />,
    nextArrow: <CustomNextArrow className={"slick-next"} />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow:slidesToShow.bp_xl,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow:slidesToShow.bp_lg,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow:slidesToShow.bp_md,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow:slidesToShow.bp_sm,
          slidesToScroll: 1
        }
      }
    ]
  };
//-------------------------------------------------------------------------------------------

  useEffect(() => { 

    const fetchTeachers = async () => {
      try {
  
        setFire(true); 
  
        let response;
        
        if (props.user === "STD") { 
          response = await axios.get(`${baseUrl}/teachers_by_std.php`, {
            params: { stdId: props.userId }
          });
        } else {
          response = await axios.get(`${baseUrl}/teachers.php`);
        }
  
        if (Array.isArray(response.data) && response.data.length) {
          setTeachers(response.data);
          updateSlidesToShow(response.data.length);
        } else {
          setTeachers([]);
        }
      } catch (error) {
        alert('Error fetching teachers:', error);
      } finally {
        setFire(false);
      }
    }

    const updateSlidesToShow = (length) => {
      const slidesToShow = {};
      Object.entries(breakpoints).forEach(([key, value]) => {
        slidesToShow[`bp_${key}`] = length < value ? length : value;
      });
      setSlidesToShow(slidesToShow);
    }

    fetchTeachers();
  }, []);
//-------------------------------------------------------------------------------------------
  
  const updateFollowingStatus = (index,following)=>{
    let temp_state = [...teachers];
    let temp_element = {...temp_state[index]};
    temp_element.following =following;
    temp_state[index] = temp_element;
    setTeachers(temp_state);
  }
//-------------------------------------------------------------------------------------------  

  return (
    <> 
      {fire?

        <div className="position-relative" style={{height:"200px"}}>
          <div className="mt-4 mb-3">
            <PageTitle text="מורים מומלצים" sub={true}/>
          </div>
          <div className="position-absolute top-50 start-50 translate-middle-x" style={{zIndex: "1",top:"300",left:'300'}}>
              <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
          </div>
        </div>
        :
        null
      }

      {teachers.length>0?
        <>
          <div className="mt-4 mb-3">
            <PageTitle text="מורים מומלצים" sub={true}/>
          </div>

          <Slider {...settings}>
            {teachers.map((t,index)=>(                                                                                              
              <div dir="rtl">
                <TeacherCard                                                     
                  index={index}
                  bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"}                                  
                  teacher={t.teacher}
                  tchId={t.tchId}
                  photo={t.photo}  
                  rating={t.rating} 
                  ratings={t.counter} 
                  following={t.following}                                                                                                                   
                  user={props.user}  
                  stdId={props.userId}
                  updateFollowingStatus={updateFollowingStatus}                                                                                                          
                /> 
              </div>                                                                                      
            ))}                          
          </Slider>         
        </>
        :
        null
      }                                  
    </>      
  );
};

export default SuggestedTeachers;

