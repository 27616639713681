import React, { useState } from "react";
import CourseBox from './course_box';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import CourseMenu from './course_menu';

const SchTchCourseBox = (props) => { 
  const [refresh,setRefresh]=useState(false);
  
  const changeIcon=(file)=>{
      props.updateIcon(file,props.index)
  }
//-------------------------------------------------------------------------------------------

  const updateCourseDtls=(subject,teacher,date)=>{
    props.updateCourseDtls(subject,teacher,date,props.index)
  
  }
//-------------------------------------------------------------------------------------------

  const refreshSchedule=()=>{
    setRefresh((prev)=>!prev)
  }
//-------------------------------------------------------------------------------------------

  return (                                                                                                                
    <CourseBox 
      bgcolor={props.bgcolor} 
      teacher={props.teacher} 
      rating={props.rating} 
      ratings={props.ratings}
      subject={props.subject} 
      price={props.price}
      date={props.date} 
      photo={props.photo} 
      private={props.private} 
      code={props.code}
      xcode={props.xcode} 
      schedule={props.schedule} 
      refresh={refresh} 
      user={props.user}
      status={props.status} 
      syllabus={props.syllabus}                   
    >
      {props.status!=="CLOSED"?         
        <CourseMenu
          index={props.index} 
          code={props.code}
          xcode={props.xcode} 
          user={props.user} 
          changeIcon={changeIcon} 
          updateCourseDtls={updateCourseDtls}
          refreshSchedule={refreshSchedule} 
          makerId={props.makerId}
          sch_maker={props.sch_maker}
          tchId={props.tchId}
          status={props.status}
          private={props.private}
          syllabus={props.syllabus}
          removeCourseFromList={props.removeCourseFromList}
          updateStatus={props.updateStatus}
          updateSyllabus={props.updateSyllabus}                
        />       
        :
        null
      }
    </CourseBox>    
  );
};

export default SchTchCourseBox;
