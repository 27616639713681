import React, {useState,useEffect} from "react";
import {Modal} from 'react-bootstrap';
import Button from '@mui/material/Button'; 
import { AiOutlineSchedule } from "react-icons/ai";
import '../Components/t_daily_prog.css';
import $ from "jquery";
import axios from 'axios';
import ReactLoading from 'react-loading';

const baseUrl = process.env.REACT_APP_BASE_URL;

const ProgBoard=(props)=>{ 
    
    const [show,setShow]=useState(false);
    const [fire,setFire]=useState(false);
    var freeTime=[];
    
    useEffect(()=>{
        
        const fetchHours = async () => {
            try {
                    const response = await axios.get(`${baseUrl}/get_daily_prog.php`,{
                        params: {
                            tchId:props.tchId
                        }
                    })
              
                    if(Array.isArray(response.data) && response.data.length > 0){
                        
                        for(var i=0 ; i<response.data.length ; i++){                         
                            var value=response.data[i].hour
                            freeTime.push(value)
                            $("#"+value).addClass("selected_lesson")
                        }

                    }                     
                    else            
                        freeTime=[]             
                    
            } 
            catch (error) {
      
              alert(error) ;
             
            } 
        }
        
        fetchHours()
        
        $(".set_lesson").on("click",function() {            
                
            $(this).toggleClass("selected_lesson") 
            
            const value = $(this).text()
            
            if($(this).hasClass("selected_lesson")){                           
                freeTime.push(value) 
            }
            else{            
                const index=freeTime.indexOf(value) 
                if (index > -1) {
                    freeTime.splice(index, 1)
                }                          
            }
                                     
        });
    });
//------------------------------------------------------------------------------------------- 

const saveDailyProg = async () => {
    setFire(true); 

    try {
        const json = JSON.stringify(freeTime);
    
        const response = await axios.get(`${baseUrl}/set_daily_prog.php`,{
            params: {
                tchId: props.tchId,
                json: json,
            }
        })

        if (response.data.status === 'success') {
            alert("Success: " + response.data.message);
        } else if (response.data.status === 'error') {
           
            throw new Error("Error: " + response.data.message);
        } else {

            throw new Error("Unexpected response from the server.");
        }
    } catch (error) {
    
        alert(error.message);   
    } finally {
        setFire(false); 
    }
};

//-------------------------------------------------------------------------------------------

    function toggleProBoard(){ 
      setShow((f)=>!f)
    };
//-------------------------------------------------------------------------------------------

    return (  
       
        <div>                    
            <button type="button" class="btn btn-outline-secondary btn-sm daily-prog-btn" onClick={toggleProBoard} style={{width:"100%"}}>מתי מתאים לך ללמד?</button>
            <Modal 
                show={show} 
                onHide={toggleProBoard} 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"        
            >  
                <Modal.Header closeButton style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
                    <div className="d-flex gap-2 align-items-center">
                        <div><AiOutlineSchedule size={35} /></div>
                        <div className="mt-1"><h5>הגדרת שעות עבודה</h5></div>
                    </div>                         
                </Modal.Header>  
                
                <Modal.Body>
                    <div class="prog-container mt-3 position-relative">

                        {fire?
                            <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex: "1"}}>
                                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
                            </div>
                            :
                            null
                        } 

                        <div class="no_content"></div>
                        <div class="dow">א</div>
                        <div class="dow">ב</div>
                        <div class="dow">ג</div>
                        <div class="dow">ד</div>
                        <div class="dow">ה</div>
                        <div class="dow">ו</div>
                        <div class="dow">שבת</div>
                        <div class="L_time">8:00 - 8:50</div>
                        <div class="set_lesson" id="1">1</div>
                        <div class="set_lesson" id="2">2</div>
                        <div class="set_lesson" id="3">3</div>
                        <div class="set_lesson" id="4">4</div>
                        <div class="set_lesson" id="5">5</div>
                        <div class="set_lesson" id="6">6</div>
                        <div class="set_lesson" id="7">7</div>
                        <div class="L_time">9:00 -9:50</div>
                        <div class="set_lesson" id="8">8</div>
                        <div class="set_lesson" id="9">9</div>
                        <div class="set_lesson" id="10">10</div>
                        <div class="set_lesson" id="11">11</div>
                        <div class="set_lesson" id="12">12</div>
                        <div class="set_lesson" id="13">13</div>
                        <div class="set_lesson" id="14">14</div>
                        <div class="L_time">10:00 -10:50</div>
                        <div class="set_lesson" id="15">15</div>
                        <div class="set_lesson" id="16">16</div>
                        <div class="set_lesson" id="17">17</div>
                        <div class="set_lesson" id="18">18</div>
                        <div class="set_lesson" id="19">19</div>
                        <div class="set_lesson" id="20">20</div>
                        <div class="set_lesson" id="21">21</div>
                        <div class="L_time">11:00 -11:50</div>
                        <div class="set_lesson" id="22">22</div>
                        <div class="set_lesson" id="23">23</div>
                        <div class="set_lesson" id="24">24</div>
                        <div class="set_lesson" id="25">25</div>
                        <div class="set_lesson" id="26">26</div>
                        <div class="set_lesson" id="27">27</div>
                        <div class="set_lesson" id="28">28</div>
                        <div class="L_time">12:00 -12:50</div>
                        <div class="set_lesson" id="29">29</div>
                        <div class="set_lesson" id="30">30</div>
                        <div class="set_lesson" id="31">31</div>
                        <div class="set_lesson" id="32">32</div>
                        <div class="set_lesson" id="33">33</div>
                        <div class="set_lesson" id="34">34</div>
                        <div class="set_lesson" id="35">35</div>
                        <div class="L_time">13:00 -13:50</div>
                        <div class="set_lesson" id="36">36</div>
                        <div class="set_lesson" id="37">37</div>
                        <div class="set_lesson" id="38">38</div>
                        <div class="set_lesson" id="39">39</div>
                        <div class="set_lesson" id="40">40</div>
                        <div class="set_lesson" id="41">41</div>
                        <div class="set_lesson" id="42">42</div>
                        <div class="L_time">14:00 -14:50</div>
                        <div class="set_lesson" id="43">43</div>
                        <div class="set_lesson" id="44">44</div>
                        <div class="set_lesson" id="45">45</div>
                        <div class="set_lesson" id="46">46</div>
                        <div class="set_lesson" id="47">47</div>
                        <div class="set_lesson" id="48">48</div>
                        <div class="set_lesson" id="49">49</div>
                        <div class="L_time">15:00 -15:50</div>
                        <div class="set_lesson" id="50">50</div>
                        <div class="set_lesson" id="51">51</div>
                        <div class="set_lesson" id="52">52</div>
                        <div class="set_lesson" id="53">53</div>
                        <div class="set_lesson" id="54">54</div>
                        <div class="set_lesson" id="55">55</div>
                        <div class="set_lesson" id="56">56</div>
                        <div class="L_time">16:00 -16:50</div>
                        <div class="set_lesson" id="57">57</div>
                        <div class="set_lesson" id="58">58</div>
                        <div class="set_lesson" id="59">59</div>
                        <div class="set_lesson" id="60">60</div>
                        <div class="set_lesson" id="61">61</div>
                        <div class="set_lesson" id="62">62</div>
                        <div class="set_lesson" id="63">63</div>
                        <div class="L_time">17:00 -17:50</div>
                        <div class="set_lesson" id="64">64</div>
                        <div class="set_lesson" id="65">65</div>
                        <div class="set_lesson" id="66">66</div>
                        <div class="set_lesson" id="67">67</div>
                        <div class="set_lesson" id="68">68</div>
                        <div class="set_lesson" id="69">69</div>
                        <div class="set_lesson" id="70">70</div>
                        <div class="L_time">18:00 -18:50</div>
                        <div class="set_lesson" id="71">71</div>
                        <div class="set_lesson" id="72">72</div>
                        <div class="set_lesson" id="73">73</div>
                        <div class="set_lesson" id="74">74</div>
                        <div class="set_lesson" id="75">75</div>
                        <div class="set_lesson" id="76">76</div>
                        <div class="set_lesson" id="77">77</div>
                        <div class="L_time">19:00 -19:50</div>
                        <div class="set_lesson" id="78">78</div>
                        <div class="set_lesson" id="79">79</div>
                        <div class="set_lesson" id="80">80</div>
                        <div class="set_lesson" id="81">81</div>
                        <div class="set_lesson" id="82">82</div>
                        <div class="set_lesson" id="83">83</div>
                        <div class="set_lesson" id="84">84</div>                                            
                    </div> 
                </Modal.Body>  
                <Modal.Footer>  
                    <div className="d-flex gap-2 me-3">
                        <Button variant="contained" color="error"   size="small" onClick={toggleProBoard}>סגור </Button>  
                        <Button variant="contained" color="success" size="small" onClick={saveDailyProg} >שמירה</Button>                        
                    </div>  
                </Modal.Footer>  
            </Modal> 
        </div>         
    )  
  } 
  
export default ProgBoard;
