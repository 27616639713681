
import React, { useState } from "react";
import '../Components/profile.css'
import DocsList from "./docs_list";

const SchSettings=(props)=> {
    
  return (
    <div className="d-flex flex-column gap-2 p-4 pt-4">                         
      <DocsList btn_txt="העלאת מסמכים" userId={props.userId} user={props.user}/>                                                                                                                                                        
    </div>      
  );
}

export default SchSettings;
