import  React, {useState,useEffect} from "react";
import PageTitle from "../Components/page_title";
import Pagination from '@mui/material/Pagination';
import SearchByDates from "../Components/search_by_date";
import StdLessonBox from "../Components/std_lesson_box";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import axios from 'axios';
import { addMinutes } from "../Components/time";
import ReactLoading from 'react-loading';
import { Box,Button } from '@mui/material';
import Divider from '@mui/material/Divider';

const baseUrl = process.env.REACT_APP_BASE_URL; 
const win=4;

const StdClassOnline = (props) => {

  const [page, setPage] = useState(1);
  const [lessons,setLessons]=useState([]);
  const [refreshPage, setRefreshPage] = useState(true);
  const [fire,setFire]=useState(false);
  const [screenHeight, setScreenHeight] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  const [lessonsStatus,setLessonStatus]= useState("TODAY");

  useEffect(() => {
    setScreenHeight(window.innerHeight-200);
  }, []);
//-------------------------------------------------------------------------------------------
        
  useEffect(()=>{

    const fetchLessons = async () => { 
      try {

        setFire(true)

        const response = await axios.get(`${baseUrl}/std_lessons.php`,{
          params: {                        
            userId:props.userId,
            filter:lessonsStatus,                                                                    
          }
      })
       
        if(Array.isArray(response.data) && response.data.length){
          setLessons(response.data) 
          setRefreshPage(prev=>prev=!prev)        
        }
        else{
          setLessons([])          
        }
        setFire(false)
      } 
      catch (error) {

        alert(error) ;
        
      }

      setPage(1)
      goToTop()
      
    }

    if(lessonsStatus!=="UNDEFINED")
      fetchLessons();
     
  },[lessonsStatus]);
//-------------------------------------------------------------------------------------------

  const set_show_overlay= (value)=>{
    setShowOverlay(value);
  }
//-------------------------------------------------------------------------------------------

  const fetchLessonsByDates = async (sDate,eDate) => {
    try {

      setFire(true) 

      const response = await axios.get(`${baseUrl}/std_lessons_by_dates.php`,{
        params: {                        
          userId:props.userId, 
          sDate:sDate,
          eDate:eDate                                                           
        }
      })
      
      if(Array.isArray(response.data) && response.data.length){
        setLessons(response.data)
        setRefreshPage(prev=>prev=!prev)        
      }
      else{
        setLessons([])
      }

      setLessonStatus("UNDEFINED")
      setFire(false)  
    } 
    catch (error) {

      alert(error) ;
      
    }

    setPage(1)
    goToTop()
  }
//-------------------------------------------------------------------------------------------

  const goToTop = () => {
    window.scrollTo({
        top:0,
        behavior: 'auto',
    })
  }
//-------------------------------------------------------------------------------------------
  const handleChangePage = (_,page)=> {
        
    if (page !== null){
      setPage(page)
      setRefreshPage(prev=>!prev)
      goToTop()
    }
  
  }
//-------------------------------------------------------------------------------------------

  const updateLessonStatus = (index)=>{

    let x=(page-1)*win+index;
    let temp_state = [...lessons];
    let temp_element = { ...temp_state[x]};
    temp_element.status ='IN_CART';
    temp_state[x] = temp_element;
    setLessons(temp_state);
    props.increaseAmount();

  }
//-------------------------------------------------------------------------------------------

  const updateLessonRating = (index,value)=>{
    
    let x=(page-1)*win+index;
    let temp_state = [...lessons];
    let temp_element = {...temp_state[x]};

    if(temp_element.lessonId!=null)
      temp_element.rating =value;
    else
      temp_element.p_rating =value;

    temp_state[x] = temp_element;
    setLessons(temp_state);   
  }
//-------------------------------------------------------------------------------------------

  return (
    
    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>

        {showOverlay && 
          <Box 
            position="fixed" 
            top={0} 
            left={0} 
            width="100%" 
            height="100%" 
            bgcolor="rgba(0,0,0,0.5)" 
            zIndex={9999}
          ></Box>
      
        }
                      
        <div className="mt-4">
          <PageTitle  text="כיתה מקוונת"/>
        </div>
        
        <div className="mt-5 ms-2">
          <SearchByDates setDateRange={fetchLessonsByDates}/>
        </div>

        <div className="mt-4">                            
          <Divider></Divider>        
        </div>

        <div className="d-flex gap-3 mt-3">
          <Button 
            variant="contained" 
            color="secondary" 
            size="small" 
            disabled={lessonsStatus === "TODAY"}
            onClick={()=>setLessonStatus("TODAY")}
          >
            השיעורים של היום
          </Button>

          <Button 
            variant="contained" 
            color="secondary" 
            size="small" 
            disabled={lessonsStatus === "FUTURE"}
            onClick={()=>setLessonStatus("FUTURE")}
          >
            שיעורים עתידיים
          </Button>

          <Button 
            variant="contained" 
            color="secondary" 
            size="small" 
            disabled={lessonsStatus === "HISTORY"}
            onClick={()=>setLessonStatus("HISTORY")}
          >
            היסטוריית שיעורים
          </Button>
        </div>
        
        <div className="d-flex flex-column position-relative gap-3 mt-2"> 

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x" style={{zIndex: "1",top:"300",left:'300'}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          }  

          {lessons.slice((page-1)*win,(page-1)*win+win).map((l,index)=>(                                                                                              
            <div>
              <StdLessonBox                                 
                index={index} 
                user={props.user}
                bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"} 
                teacher={l.lessonId!=null?l.teacher:l.p_teacher} 
                rating={l.lessonId!=null?l.rating:l.p_rating}
                ratings={l.p_rating>0||l.rating>0?1:0} 
                subject={l.lessonId!=null?l.subject:l.p_subject} 
                date={l.lessonId!=null?l.date:l.p_date}
                start={l.lessonId!=null?l.start:l.p_start} 
                lessons_num={l.lessonId!=null?l.num:l.p_num}
                end={l.lessonId!=null?addMinutes(l.start,l.num*50):addMinutes(l.p_start,l.p_num*50)} 
                price={l.lessonId!=null?l.price*l.num:l.p_price*l.p_num}  
                photo={l.photo} 
                userId={props.userId}
                tchId={l.lessonId!=null?l.tchId:l.p_tchId}
                lessonId={l.lessonId!=null?l.lessonId:l.p_lessonId}
                lessonsNum={l.lessonId!=null?l.num:l.p_num}
                status={l.lessonId!=null?l.status:l.p_status}  
                cal={l.lessonId!=null?l.CAL:l.p_CAL}                                                                                            
                updateLessonStatus={updateLessonStatus}
                updateLessonRating={updateLessonRating}
                mode={l.lessonId!=null?'GROUP':'PRIVATE'}
                platform={l.lessonId!=null?l.platform:l.p_platform}
                recording={l.lessonId!=null?l.recording:l.p_recording}
                done={l.lessonId!=null?l.done:l.p_done}
                std_done={l.lessonId!=null?l.std_done:l.pstd_done}
                cancelled={l.lessonId!=null?l.cancelled:l.p_cancelled}
                std={true}  
                refresh={refreshPage}
                set_show_overlay={set_show_overlay}
              />
            </div>            
          ))} 

        </div>
        
        <div  class="d-flex justify-content-end mt-4" dir="ltr">                     
          <Pagination count={Math.ceil(lessons.length/win)} variant="outlined" shape="rounded" color="primary" page={page} onChange={handleChangePage}/>         
        </div>

    </div>
    
  );
};

export default StdClassOnline;



