
import React, { useState } from "react";
import '../Components/profile.css'
import ProgBoard from "./t_daily_prog";
import DocsList from "./docs_list";
import InstitutesList from "./institutes_list ";
import ZoomSettings from "./zoom_settings";

const Settings=(props)=> {
    
  return (
    <div className="d-flex flex-column gap-2 p-4 pt-4"> 

      <ProgBoard  
        tchId={props.userId}
      /> 

      <InstitutesList 
        btn_txt="שיוך למרכז לימוד" 
        tchId={props.userId}
      />

      <DocsList 
        btn_txt="העלאת מסמכים" 
        userId={props.userId} 
        user={props.user}
      /> 

      <ZoomSettings 
        tchId={props.userId} 
      /> 
                                                                                                                                                      
    </div>      
  );
}

export default Settings;
