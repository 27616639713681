
import React, { useState,useEffect} from "react";
import dateFormat, { masks } from "dateformat";
import DatePicker from "react-datepicker";
import { addDays } from "./time"
import "react-datepicker/dist/react-datepicker.css";
import './popup_modals.css';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const AddMeetingLine=(props)=>{ 
    
    const [lessonDate,setLessonDate]=useState(addDays(new Date(), 0));
    const lessonsNum=["1","2","3","4"];
    const [maxNum,setMaxNum]=useState(0);
    const [times,setTimes]=useState([]);
    
    const [lessonDtls, setLessonDtls] = useState({        
        time:"",
        num:1  
    });
//-------------------------------------------------------------------------------------------     

    useEffect(()=>{
        
        const fetchAvailability = async () => {
                        
            masks.hammerTime = 'yyyy-mm-dd';
            
            try {
                
                const response = await axios.get(`${baseUrl}/get_availability.php`,{
                    params: {
                        tchId:props.tchId,
                        date:dateFormat(lessonDate, "hammerTime"),
                        dow:lessonDate.getDay()                       
                    }
                })
                
                if(response.data.status==='success'){
                    setTimes(response.data.times)
                    setLessonDtls(prevState => ({
                        ...prevState,
                        ['time']:response.data.times[0].id,
                        ['num']:1             
                    })) 
                }     
                else {
                        setTimes([]);

                        if (response.data.status==='error') {
                            throw new Error(response.data.message || 'An unknown error occurred');                        
                        }                      
                }
                                               
          } 
          catch (error) {
            alert(error.message) ;            
          }
          
        }
         
        fetchAvailability();

        
    },[lessonDate,props.refresh]);
//-------------------------------------------------------------------------------------------         

    useEffect(()=>{
                
        let index= times.findIndex(t => parseInt(t.id) === parseInt(lessonDtls.time))
        let count=1
        let flag=true
        
        if(index>-1){

            while(index < times.length-1 && flag){ 
            
                if( parseInt(times[index].id)+7 === parseInt(times[index+1].id))
                    count++                                                           
                else
                    flag=false
                

                index++  
                                            
            }
            
            setMaxNum(count)

        }
        
    },[lessonDtls.time]);
//-------------------------------------------------------------------------------------------         

    const handleChange = e => {
        const { name, value } = e.target;
        setLessonDtls(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
//------------------------------------------------------------------------------------------- 
    
    const addNewLine = async () =>{          
            
        const time=times[times.findIndex(t => t.id.toString() === lessonDtls.time.toString())].hour
        
        masks.hammerTime = 'yyyy-mm-dd';

        const currentDate = new Date(); // Get the current date and time
        const date=dateFormat(lessonDate, "hammerTime");
        const lessonDateTime = new Date(date + ' ' + time); // Combine lesson date and time
        const timeDiff = lessonDateTime.getTime() - currentDate.getTime(); // Calculate the time difference in milliseconds

        const twelveHoursInMilliseconds = 12 * 60 * 60 * 1000; // Convert 12 hours to milliseconds

        if (timeDiff >= twelveHoursInMilliseconds){

            try{
                props.turnFireOn();
                
                const response = await axios.get(`${baseUrl}/add_meeting_line.php`,{
                    params: {
                        tchId:props.tchId, 
                        stdId:props.stdId, 
                        code:props.code,
                        price:props.price,
                        date:date,
                        num:lessonDtls.num,
                        time:lessonDtls.time 
                    }
                })

                if(response.data.status==='success'){
                    
                    const line= {   lessonId:response.data.id, 
                                    date:date, start:time, 
                                    num:lessonDtls.num, 
                                    price:props.price,
                                    status:'NO_RESPONSE',
                                };
                    props.add(line);                                                
                }
                else{

                    throw new Error(response.data.message);
                }
                
            }
            catch(error){

                alert(error.message)
            } finally {

                props.turnFireOff();
            }

        } else {
            alert("This lesson cannot be booked since the time gap is less than 12 hours!")
        }                           
    }
//-------------------------------------------------------------------------------------------

    return (  
                
        <div className="d-flex flex-wrap gap-4 align-items-center">

            <div className="label-100-wrap">                    
                <label className="form-label label-100" for="L-date">תאריך</label>
                <div id="L-date" dir="ltr">              
                    <DatePicker 
                        wrapperClassName="date-picker"  
                        selected={lessonDate} 
                        onChange={(date)=>{setLessonDate(date)}} 
                        popperPlacement="bottom-end"
                        minDate={addDays(new Date(), 0)} 
                        dateFormat="yyyy-MM-dd"                       
                    />                      
                </div>
            </div> 
                        
            <div className="label-100-wrap">
                <label class="form-label label-100" for="L-time">שעה</label>              
                <div dir="ltr">
                    <select class="form-select" id="L-time" name="time" value={lessonDtls.time} onChange={handleChange}>                                           
                        {times.length>0?
            
                            times.map((t)=>(
                                <option value={t.id}>{t.hour}</option>
                            ))

                            :null                                                      
                        }                                          
                    </select> 
                </div>
            </div>

            <div className="label-100-wrap">
                <label class="form-label label-100" for="L-num">מס' שיעורים</label>              
                <div>
                    <select class="form-select" id="L-num" name="num" style={{width:"100px"}} value={lessonDtls.num} onChange={handleChange} >
                    {
                        lessonsNum.slice(0,maxNum).map((num,index)=>(
                            <option value={index+1}>{num}</option>
                        ))
                    }                                         
                    </select> 
                </div>
            </div>
            
            <div>            
                <button 
                    type="submit" 
                    class="btn btn-primary btn-sm" 
                    style={{borderRadius:"20px",width:"70px"}} 
                    onClick={addNewLine} 
                    disabled={times.length===0}
                >
                    הוסף
                </button>
            </div>                                                                              
            
        </div> 
                          
    )  
  } 
  
export default AddMeetingLine;  
