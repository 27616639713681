import './custom_arrows.css'

const CustomNextArrow = (props) => {
    const {className, onClick} = props
    
    return (
      <div className={className} onClick={onClick}>
        <i className="fa fa-arrow-left"></i>
      </div>
    );
};
export default CustomNextArrow;
  
