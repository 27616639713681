import React,{useState} from 'react';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import MailIcon from '@mui/icons-material/Mail';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right:26,
      top: 2,
      border: `1px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
}));

const MessageBadge = (props)=> {
        
    return (
    
      <StyledBadge 
        badgeContent={props.count}  
        color="secondary"
      >
        <MailIcon  fontSize='medium'/>
      </StyledBadge>
      
  );
}

export default MessageBadge;
