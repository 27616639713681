import  React, {useState,useEffect} from "react";
import PageTitle from "../Components/page_title";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import DocumentConBox from "../Components/doc_confirm_box";
import dateFormat, { masks } from "dateformat";
import ReactLoading from 'react-loading';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL; 

const ConfirmDocuments = (props) => {

  const [docs,setDocs]=useState([]);
  const [screenHeight, setScreenHeight] = useState(0);
  const [fire,setFire]=useState(false);

  useEffect(() => {
    setScreenHeight(window.innerHeight-200);
  }, []);
//-------------------------------------------------------------------------------------------
  
  useEffect(()=>{
    
    const fetchDocuments = async () => {
      try {
          setFire(true);
  
          let response;
          if (props.user === "ADMIN") {
              response = await axios.get(`${baseUrl}/docs_to_confirm.php`);
          } else {
              response = await axios.get(`${baseUrl}/docs_to_confirm_by_sch.php`, {
                  params: { schId: props.id }
              });
          }
          
          if (response.data.status === 'error') {
              throw new Error(response.data.message || 'An error occurred while fetching documents');
          }
  
          if (response.data.status === 'success') {
              setDocs(response.data.records || []);
          } else {
              throw new Error('Unexpected response status');
          }
      } catch (error) {
          alert('Error: ' + error.message);
          setDocs([]);
      } finally {
          setFire(false);
      }
  };
  
  
    fetchDocuments();
        
  },[]);
//-------------------------------------------------------------------------------------------

  const updateDocStatus = async (userId, docId, user, comment, expDate, status) => {
    try {
      
      let response;
      masks.hammerTime = 'yyyy-mm-dd';

      if(props.user==="ADMIN"){
        response=await axios.get(`${baseUrl}/update_doc_status.php`,{
          params: {
              userId:userId,
              docId:docId, 
              user:user,
              comment:comment,
              expDate:dateFormat(expDate, "hammerTime"),
              status:status,                                       
          }
        })
      } else {
        response=await axios.get(`${baseUrl}/update_doc_status_by_sch.php`,{
          params: {
              docId:docId,
              user:user,
              comment:comment,
              expDate:dateFormat(expDate, "hammerTime"),
              status:status                                         
          }
        })
      }

      if (!response.data || typeof response.data !== 'object') {
          throw new Error("Invalid response from server");
      }

      if (response.data.status === 'error') {
          throw new Error(response.data.message);
      }

    } catch (error) {
       
        throw error; 
    }
  }
//-------------------------------------------------------------------------------------------

   return (

    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>
                      
        <div className="mt-4">
          <PageTitle text="אישור מסמכים"/>
        </div>
        
        <div className="d-flex flex-column gap-2 mt-5 position-relative">

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex: "1"}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          }

          {docs.map((doc,index)=>(                                                                                                          
            <DocumentConBox 
              index={index+1}
              bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"}                                                   
              userName={doc.f_name +" " + doc.l_name}
              userId={doc.userId}
              user={doc.t_docId!==null?"TCH":"SCH"}
              date={doc.t_docId!==null?doc.t_date:doc.s_date}
              file={doc.t_docId!==null?doc.t_file:doc.s_file}                                                               
              docId ={doc.t_docId!==null?doc.t_docId:doc.s_docId}                                      
              status={doc.t_docId!==null?doc.t_status:doc.s_status} 
              docType={doc.t_docId!==null?doc.t_docName:doc.s_docName}
              recipient={doc.t_docId!==null?doc.t_recipient:doc.s_recipient}
              decreaseDocsNum={props.decreaseDocsNum} 
              updateDocStatus={updateDocStatus}                                                                                                                          
            />                        
          ))}
        </div>               
    </div>
  );
};

export default ConfirmDocuments; 


