import React, { useState } from "react";
import '../Components/course_icon.css'

const CourseIcon=(props)=> {
          
    return (
        <div className={props.checked===props.value?"normal checked":"normal"} onClick={() => props.getValueFromIcon(props.value)}>        
            <img className="course-icon" src={props.icon}></img>
        </div>
    );
}

export default CourseIcon;      
