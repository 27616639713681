import React, { useState,useEffect } from "react";
import dateFormat, { masks } from "dateformat";
import axios from 'axios';
import { alertClasses } from "@mui/material";

const baseUrl = process.env.REACT_APP_BASE_URL;

const SelectTime=(props)=>{ 
                       
    const [times,setTimes]=useState([]);
    const [time,setTime]=useState(0);
       
    useEffect(()=>{
        
        const fetchAvailability = async () => { 
                        
            masks.hammerTime = 'yyyy-mm-dd';

            try {
                
                if(props.editing)
                    var response = await axios.get(`${baseUrl}/get_availability_by_lesson_id.php`,{
                        params: {
                            tchId:props.tchId,
                            lessonId:props.lessonId,
                            date:dateFormat(props.date, "hammerTime"),
                            dow:props.date.getDay()                       
                        }
                    })                    
                else
                    var response = await axios.get(`${baseUrl}/get_availability.php`,{
                        params: {
                            tchId:props.tchId,
                            date:dateFormat(props.date, "hammerTime"),
                            dow:props.date.getDay()                       
                        }
                    })
                    
                                    
                if(response.data.status==='success'){
                    setTimes(response.data.times)
                    if(props.time===0){
                        setTime(response.data.times[0].id)
                        if(!props.editing) 
                            props.setLessonsNum(1)                          
                    }
                    else
                        setTime(props.time) 
                }     
                else{

                    setTimes([]); 
                    setTime(-1) 
                    props.setLessonsNum(0)
                    props.setMaxNum(0)

                    if (response.data.status==='error') {
                        throw new Error(response.data.message || 'An unknown error occurred');                        
                    } 
                }                                               
          } 
          catch (error) {

            alert(error.message) ;
          }          
        }
         
        fetchAvailability();
        
    },[props.date]);

//------------------------------------------------------------------------------------------- 
  
    useEffect(()=>{
            
        let index= times.findIndex(t => parseInt(t.id)===parseInt(time))
        let count=0
        let flag=true
        
        if(index>-1){

            const start=index

            while(index < times.length-1 && flag){ 
            
                if( parseInt(times[index].id)+7 === parseInt(times[index+1].id))
                    count++                                                           
                else
                    flag=false

                index++                                              
            }
            count++
            props.setTime(time)
            props.setMaxNum(count) 
            props.setStartTime(times[start].hour)
        }                 
                
    },[time]); 
//------------------------------------------------------------------------------------------- 

    const handleChange = (event) => {

        setTime(event.target.value)
        props.setLessonsNum(1)              
    }
//------------------------------------------------------------------------------------------- 

    return (  
        <div>                
            <div className="label-100-wrap">
                <label class="form-label label-100" for="time">שעה</label>              
                <div dir="ltr">
                    <select 
                        className={`form-control ${props.error? 'invalid' : ''}`} 
                        name="time" 
                        value={time} 
                        onChange={handleChange}
                    >                                           
                        {times.length>0?
            
                            times.map((t)=>(
                                <option value={t.id}>{t.hour}</option>
                            ))

                            :null                                                      
                        }                                          
                    </select>                                     
                </div>
                {props.error && <p className="error">{props.error}</p>}
            </div>
                                                                                                                    
        </div>        
    )  
  } 
  
export default SelectTime;  
