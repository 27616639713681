
import  React, {useState,useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import axios from 'axios';
import CourseCard from "./course_card";
import PageTitle from "./page_title";
import Slider from "react-slick";
import CustomNextArrow from "./custom_next_arrows";
import CustomPrevArrow from "./custom_prev_arrows";
import ReactLoading from 'react-loading';

const baseUrl = process.env.REACT_APP_BASE_URL;

const SuggestedCourses = (props) => {

  const [fire,setFire]=useState(false);
  const breakpoints={
    xxl:5,
    xl:4,
    lg:3,
    md:2,
    sm:2
  }
  const [courses,setCourses]=useState([]);
  
  const [slidesToShow,setSlidesToShow]=useState({
    bp_xxl:0,
    bp_xl:0,
    bp_lg:0,
    bp_md:0,
    bp_sm:0
  });

  const settings = {
    dots:false,
    infinite: true,
    speed: 500,
    slidesToShow:slidesToShow.bp_xxl,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow className={"slick-prev"} />,
    nextArrow: <CustomNextArrow className={"slick-next"} />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow:slidesToShow.bp_xl,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow:slidesToShow.bp_lg,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow:slidesToShow.bp_md,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow:slidesToShow.bp_sm,
          slidesToScroll: 1
        }
      }
    ]
  };
//-------------------------------------------------------------------------------------------

useEffect(() => {

  const fetchCourses = async () => {
    try {
      setFire(true); 

      let response;
      const userRole = props.user;
      const userID = props.userId;

      if (userRole === "STD") {
        response = await axios.get(`${baseUrl}/courses_by_std.php`, {
          params: { stdId: userID }
        });
      } else {
        response = await axios.get(`${baseUrl}/courses.php`);
      }

      if (Array.isArray(response.data) && response.data.length) {
        setCourses(response.data);
        updateSlidesToShow(response.data.length);
      } else {
        setCourses([]);
      }
    } catch (error) {
      alert('Error fetching courses:', error);
      
    } finally {
      setFire(false);
    }
  };

  const updateSlidesToShow = (length) => {
    const slidesToShow = {};
    Object.entries(breakpoints).forEach(([key, value]) => {
      slidesToShow[`bp_${key}`] = length < value ? length : value;
    });
    setSlidesToShow(slidesToShow);
  };

  fetchCourses();
  
}, []);

//-------------------------------------------------------------------------------------------
  
  const updateCourseStatus = (index,code)=>{

    let temp_state = [...courses];
    let temp_element = {...temp_state[index]};
    temp_element.reg =code;
    temp_state[index] = temp_element;
    setCourses(temp_state);
  }
//-------------------------------------------------------------------------------------------  


    return (
      <>
        {fire?

          <div className="position-relative" style={{height:"200px"}}>
            
            <div className="mt-4 mb-3">
              <PageTitle text="קורסים מומלצים" sub={true}/>
            </div>

            <div className="position-absolute top-50 start-50 translate-middle-x" style={{zIndex: "1",top:"300",left:'300'}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
          </div>
          :
          null
        }

        {courses.length>0?
          <>
            <div className="mt-4 mb-3">
              <PageTitle text="קורסים מומלצים" sub={true}/>
            </div>
                          
            <Slider {...settings}>

              {courses.map((c,index)=>(                                                                                              
                  <div dir="rtl">                                        
                    <CourseCard
                      index={index}
                      bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"}                                  
                      teacher={c.teacher} 
                      rating={c.rating} 
                      ratings={c.counter} 
                      subject={c.subject} 
                      date={c.date} 
                      photo={c.photo}                   
                      syllabus={c.syllabus}
                      code={c.code} 
                      reg={c.reg}
                      user={props.user}  
                      userId={props.userId}
                      mode={c.mode}
                      updateCourseStatus={updateCourseStatus}                                                                                                          
                    /> 
                  </div>                                                                                      
              ))}
                                
            </Slider>              
            
          </>
          :
          null
        }
                                           
      </>      
  );
};

export default SuggestedCourses;

