import React from "react";
import Divider from './divider';
import './bill.css';

const Bill = (props) => {
    const tax = Number(process.env.REACT_APP_TAX);
    const taxRate = (tax * 100).toFixed(2) + '%';
    
    // Calculate the amount covered by credit (ensure it doesn't exceed the total before tax)
    const creditUsed = Math.min(props.credit, props.total);
    
    // Calculate the remaining balance after credit is applied (before tax)
    const remainingBalanceBeforeTax = props.total - creditUsed;
    
    // Calculate tax on the remaining balance
    const taxOnRemainingBalance = remainingBalanceBeforeTax * tax;
    
    // Calculate the final amount due including tax
    const totalDue = remainingBalanceBeforeTax + taxOnRemainingBalance;
    
    return (
        <div className="price-box p-3 pb-3 pb-lg-3">
            
            <div className="pb-0 pb-lg-1">
                <h5><b>סיכום עסקה</b></h5>
            </div>

            <Divider color="rgb(0,0,0,0.3)" height="2px"/>

            <div className="container">

                <div className="mt-4 d-flex justify-content-between price-info">
                    <span>סיכום ביניים</span>
                    <span>{props.total} ₪</span>
                </div>
            
                {props.credit > 0 && (
                    <>
                        <div className="mt-2 d-flex justify-content-between price-info">
                            <span>ניצול אשראי</span>
                            <span>{creditUsed.toLocaleString(undefined, {maximumFractionDigits:2})} ₪</span>
                        </div>

                        <div className="mt-2 d-flex justify-content-between price-info">
                            <span>יתרה לתשלום לא כולל מע"מ</span>
                            <span>{remainingBalanceBeforeTax.toLocaleString(undefined, {maximumFractionDigits:2})} ₪</span>
                        </div>
                    </>
                )}

                <div className="mt-2 d-flex justify-content-between price-info">
                    <span>מע"מ {taxRate}</span>
                    <span>{taxOnRemainingBalance.toLocaleString(undefined, {maximumFractionDigits:2})} ₪</span>
                </div>

            </div>

            <Divider color="rgb(0,0,0,0.3)"/>

            <div className="container mt-2 d-flex justify-content-between price-info">
                <span><b>סה"כ לתשלום כולל מע"מ</b></span>
                <span><b>{totalDue.toLocaleString(undefined, {maximumFractionDigits:2})} ₪</b></span>
            </div>
        </div> 
    );  
};

export default Bill;
