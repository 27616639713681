import React, {useState,useEffect} from "react";
import PageTitle from "../Components/page_title";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import TeacherConBox from "../Components/tch_confirm_box";
import ReactLoading from 'react-loading';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL; 

const ConfirmTeachers = (props) => {

  const [tchs,setTchs]=useState([]);
  const [screenHeight, setScreenHeight] = useState(0);
  const [fire,setFire]=useState(false);
  
  useEffect(() => {
    setScreenHeight(window.innerHeight-200);
  }, []); 
//-------------------------------------------------------------------------------------------

  useEffect(()=>{ 
    
    const fetchTeachers = async () => {
      try {
          setFire(true); 
  
          let response;
          if (props.user === "ADMIN") { 
              response = await axios.get(`${baseUrl}/tchs_to_confirm.php`);
          } else {
              response = await axios.get(`${baseUrl}/tchs_to_confirm_by_sch.php`, {
                  params: { schId: props.id }
              }); 
          }
  
          if (response.data.status === 'success') {
              setTchs(Array.isArray(response.data.records) ? response.data.records : []);
          } else if (response.data.status === 'error') {
              throw new Error(response.data.message || 'An unknown error occurred');
          }
  
      } catch (error) {
          alert('Error fetching teachers: ' + error.message);
          setTchs([]); 
      } finally {
          setFire(false);
      }
    };
  
    fetchTeachers();
        
  },[]);
//-------------------------------------------------------------------------------------------

  const updateTchStatus = async (userId, comment, status) => {
    try {

        const endpoint = props.user === "ADMIN" ? 
            `${baseUrl}/update_tch_status.php` : 
            `${baseUrl}/update_tch_status_by_sch.php`;

        const params = props.user === "ADMIN" ? 
            { userId, comment, status } : 
            { tchId: userId, schId: props.id, comment, status };

        const response = await axios.get(endpoint, { params });

        if (response.data.status === 'success') {
          return response.data;        
        } else {
          throw new Error(response.data.message || 'An unknown error occurred');
        }

    } catch (error) {
        
      throw new Error(`Failed to update teacher status: ${error.message}`);
    }
}

//-------------------------------------------------------------------------------------------

   return (

    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>
                      
        <div className="mt-4">
          <PageTitle text="אישור מורים"/>
        </div>
                
        <div className="d-flex flex-column gap-2 mt-5 position-relative">

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex: "1"}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          }

          {tchs.map((tch,index)=>(                                                                                                          
            <TeacherConBox 
              index={index+1}
              bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"}                                                   
              userName={tch.f_name +" " + tch.l_name}
              update={tch.up_date}
              userId={tch.userId}  
              user={props.user}
              adminId={props.id}
              id={tch.id} // user Id, like 033423013
              photo={tch.photo} 
              updateTchStatus={updateTchStatus}                                                                                                                                                   
              decreaseTchsNum={props.decreaseTchsNum}                                                                                                                           
            />                       
          ))}
        </div>               
    </div>
  );
};

export default ConfirmTeachers;


