import React, { useState } from "react";
import './popup_modals.css';
 
const SelectPdfFile = (props) => { 
    
    const [selectedFile,setSelectedFile]=useState(null);
   
//-------------------------------------------------------------------------------------------      

    const changeHandler = (event) => {
        const myFile=event.target.files[0]
        const fileDtls=myFile.name.split(".")
        
        if(fileDtls.length===2){
            const format=fileDtls[1].toLowerCase()
            if(format==='pdf'){
                setSelectedFile(myFile)
                props.selectPdf(myFile)
            }
            else{
                alert("סוג הקובץ אינו מתאים!!")
                document.getElementById('file').value= null; 
                setSelectedFile(null)
            }
        }
        else{
            alert("שם קובץ אינו תקין!!")
            document.getElementById('file').value= null; 
            setSelectedFile(null)
        }                    
    }
//-------------------------------------------------------------------------------------------
        
    return (  
                                                          
        <div className="d-flex flex-wrap gap-4 align-items-center"> 

            <div className="label-100-wrap" >
                <label class="form-label label-100" for="file">בחר קובץ PDF</label>              
                <div>
                    <input className="form-control" type="file" name="file" id="file" onChange={changeHandler} style={{maxWidth:"270px"}}/>
                </div>
            </div>                                                                                          
            
        </div>                                 
    )  
  } 
  
export default SelectPdfFile;
