
import React, { useState,useEffect,useRef } from "react";
import dateFormat, { masks } from "dateformat";
import { FcApproval,FcCancel } from "react-icons/fc";
import { MdCancel } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa";
import { RiRefund2Fill } from "react-icons/ri";
import { GiSandsOfTime } from "react-icons/gi";
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import '../Components/meeting_req_box.scss'
import axios from 'axios';
import noIcon from '../Assets/Images/math3.png'
import { ThemeProvider,createTheme } from '@mui/material/styles';
import UpdateLessonStatus from "./update_lesson_status";
import { updateLessonSubject,updateMeetingStatus,newZoomMeeting } from "./db";

const baseUrl = process.env.REACT_APP_BASE_URL;

const theme = createTheme({
  palette: {
    success: {
      main: '#008000', // Replace with your desired color value
    },
  },
});

const MeetingReqBox = (props) =>{
  const [status,setStatus]=useState(props.status);
  const statusRef = useRef(status);
  const [selectedPlatform, setSelectedPlatform] = useState('ZOOM');

  useEffect(() => {
    // Update the ref whenever the status state changes
    statusRef.current = status;
  }, [status]);

  useEffect(() => {

    let interval;
    let isFetching = false;

    // Function to fetch and update the status
    const fetchStatus = async () => {

      if (isFetching) return; // Prevents new fetch if the previous one hasn't finished
        isFetching = true;

      masks.hammerTime = 'yyyy-mm-dd';
      const currentDate = new Date(); // Get the current date and time
          
      try {
        const response = await axios.get(`${baseUrl}/get_p_lesson_status.php`, {
          params: {
            lessonId: props.lessonId
          }
        });

        if (response.data.status) {

          if(status ==='NO_RESPONSE' && response.data.status === 'REMOVED' )
            props.decreaseRequestsNum()

          setStatus(response.data.status);
        
          if (response.data.status !== 'PAID' && response.data.status !== 'REMOVED') {
            const date = dateFormat(props.date, "yyyy-mm-dd");
            const lessonDateTime = new Date(date + ' ' + props.start); // Combine lesson date and time
            const timeDiff = lessonDateTime.getTime() - currentDate.getTime(); // Calculate the time difference in milliseconds
            const twelveHoursInMilliseconds = 12 * 60 * 60 * 1000; // Convert 12 hours to milliseconds
    
            if (timeDiff < twelveHoursInMilliseconds || (response.data.status === 'APPROVED' && currentDate.getTime() - new Date(response.data.sDate).getTime() > twelveHoursInMilliseconds)) {
              setStatus('TIMEOUT');
            }

          } else if (response.data.status === 'PAID' || response.data.status === 'REMOVED') {
              clearInterval(interval);
          }

        }
  
      } catch (error) {

          alert(error);
      } finally {
        
        isFetching = false; // Reset the flag whether it succeeded or failed
      }
    }

    interval = setInterval(fetchStatus, 5000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);

  }, []);

 
//-------------------------------------------------------------------------------------------

const confirm = async (subject) => {
  try {

      props.turnFireOn()
      
      const responseMeet = await newZoomMeeting(props.lessonId, props.tchId, "PRIVATE", props.subject, selectedPlatform);

      let setStatusValue = "APPROVED";

      if (responseMeet.code === 0 && responseMeet.redirectUrl) {

          const childWindow = window.open(responseMeet.redirectUrl, '_blank', 'width=550,height=600,left=359,top=100');
          
          props.set_show_overlay(true)

          const childDataResult = await new Promise((resolve) => {
            
            const checkInterval = setInterval(() => {

              const childData = getCookie("childData");

              if (childWindow.closed ) {

                clearInterval(checkInterval);
                                    
                if (childData !== undefined) {
                  deleteCookie("childData");
                  resolve(childData === 'true'); 
                }
                else
                  resolve(false);
              }

            },500);
          });
          
          setStatusValue = childDataResult ? "APPROVED" : "NOT_APPROVED";
          props.set_show_overlay(false)
      }

      

      if (setStatusValue === "APPROVED") {
           
          const responseSubject = await updateLessonSubject(subject, props.lessonId, "PRIVATE");
          const responseStatus = await updateMeetingStatus(props.lessonId, setStatusValue);

          if (status === 'NO_RESPONSE') {
              props.decreaseRequestsNum();
          }

          setStatus(setStatusValue);
      }

  } catch (error) {
      alert(error.message);
  } finally {
    props.turnFireOff()
  }
};
//-------------------------------------------------------------------------------------------

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
//-------------------------------------------------------------------------------------------

  function deleteCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
  }
//-------------------------------------------------------------------------------------------

  const deny= async () => {
    
    try{
      
      props.turnFireOn()
      const responseStatus= await updateMeetingStatus(props.lessonId,'NOT_APPROVED');
      
      if(status==='NO_RESPONSE')
        props.decreaseRequestsNum()

      setStatus("NOT_APPROVED")

    } catch(error) {

      alert(error.message)
    } finally {
      props.turnFireOff()
    }   
    
  }
//-------------------------------------------------------------------------------------------

  const handlePlatformChange = e => {
    setSelectedPlatform(e.target.value);
    
  }
//-------------------------------------------------------------------------------------------

  return (
    <div className="meeting-req-box" style={{background: props.bgcolor}}>
      
      <div className="subject-icon-container">
        <div className="frame">
          <img 
            src={props.photo != null ? props.photo : noIcon} 
            alt="subject icon" 
            className="subject-icon"  
          /> 
        </div>       
      </div>

      <div className="date-time">
        <p className="date">{props.date}</p>
        <p className="time">{`${props.start} - ${props.end}`}</p>
      </div>

      <div class="lesson-num">{props.num}</div>
      
      <div class="price">{`₪${props.price}`}</div>

      <div className="confirm">
                 
        <UpdateLessonStatus 
          link={true} 
          btnText="מאשר" 
          titleTxt="אישור מפגש"
          submitText="אשר"
          getSubject={confirm}
          disabled={ status === "APPROVED" || status === "PAID" || status === "REMOVED" || status === "TIMEOUT"|| status==="REFUNDED" }
        >
          <div className="mb-2 mt-4 d-flex flex-column gap-2">
            <label>מפגש מקוון בעזרת:</label>

            <div className="d-flex flex-row gap-4">
                <div className="form-check form-check-inline">
                    <input 
                        className="form-check-input"
                        type="radio" 
                        id="zoomOption"
                        value="ZOOM" 
                        checked={selectedPlatform === 'ZOOM'} 
                        onChange={handlePlatformChange}
                    />
                    <label className="form-check-label" htmlFor="zoomOption">ZOOM</label>
                </div>
                <div className="form-check form-check-inline">
                    <input 
                        className="form-check-input"
                        type="radio" 
                        id="googleMeetOption"
                        value="GOOGLE_MEET" 
                        checked={selectedPlatform === 'GOOGLE_MEET'} 
                        onChange={handlePlatformChange}
                        disabled
                    />
                    <label className="form-check-label" htmlFor="googleMeetOption">GOOGLE MEET</label>
                </div>
            </div>
          </div> 
        </UpdateLessonStatus>
                                              
        <button 
          type="button" 
          className="btn btn-outline-danger btn-sm btn-custom"
          onClick={deny}           
          disabled={ status==="NOT_APPROVED" || status==="PAID" || status==="REMOVED" || status==="TIMEOUT" || status==="REFUNDED"}
        >לא מאשר</button>
                                          
        <span>
          { status==='NO_RESPONSE'?
              <FaRegEyeSlash color="red" size={30}/>
            :status==="APPROVED"?
              <FcApproval size={30}/>
            :status==="PAID"?
              <ThemeProvider theme={theme}>
                <CreditScoreIcon size={30} color="success" />
              </ThemeProvider>
            :status==="REFUNDED"?
              <ThemeProvider theme={theme}>
                <RiRefund2Fill size={30} color="red"/>
              </ThemeProvider>
            :status==="REMOVED"?
              <FcCancel size={30}/>
            :status==="TIMEOUT"?
              <GiSandsOfTime size={30}/>
            :
              <MdCancel color="red" size={30}/>
          }
        </span>

      </div>                                                                                                 
    </div>                                           
  )
};
export default MeetingReqBox;

  
