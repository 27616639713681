import React, {useState,useEffect,useRef} from "react";
import PageTitle from "../Components/page_title";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import Divider from '../Components/divider'
import ReportSummary from "../Components/report_summary";
import ReportByMonth from "../Components/report_by_month";
import SummaryLine from "../Components/summary_line";
import ReactLoading from 'react-loading';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL; 

const SummaryReportByTch = (props) => {

  const [reportItems,setReportItems]=useState([]);
  const [refresh,setRefresh]=useState(false);   
  const total = useRef(0);
  const [screenHeight, setScreenHeight] = useState(0);
  const [fire,setFire]=useState(false);

  useEffect(() => {
    setScreenHeight(window.innerHeight-200);
  }, []);
//-------------------------------------------------------------------------------------------
                 
  const  setDateRange = async (sDate,eDate) => {
    
    total.current=0

    try {

      setFire(true)
      const response = await axios.get(`${baseUrl}/summary_report_by_tch.php`,{
        params: {                                   
          sDate:sDate,
          eDate:eDate                                                                
        }
    })
              
      if(Array.isArray(response.data) && response.data.length){
        setReportItems(response.data)
        setFire(false)
      }
      else{
        setReportItems([])
        setFire(false)
      }
        
    } 
    catch (error) {

      alert(error) ;
      
    }

    setRefresh(prev=>!prev)
    
  }     
//-------------------------------------------------------------------------------------------
  
  const updateTotal =(price) =>  {
    
    total.current=total.current+price      
  }
//-------------------------------------------------------------------------------------------

  return (

    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>
                      
        <div className="mt-4">
          <PageTitle text="דוח מסכם"/>
        </div>
        
        <div className="mt-5 ms-2">
          <ReportByMonth setDateRange={setDateRange}/>
        </div>
                 
        <div className="mt-4">
          <Divider height="2px" color="rgb(255, 0, 0,0.2)"/> 
        </div>

        <div className="d-flex flex-column gap-2 mt-5 position-relative">

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex: "1"}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          }

          {reportItems.map((item,index)=>(                                                                                                                      
            <SummaryLine
              index={index}
              bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"}                                                 
              name={item.teacher!==null?item.teacher:item.center}                                                                                                           
              price={item.price!==null?item.price:item.p_price}
              amount={item.num!==null?item.num:item.p_num}
              updateTotal={updateTotal}                                                                                                                                                                                   
            />                                    
          ))}
        </div>
      
      {reportItems.length>0?
        <>                                                                                                              
          <div className="mt-4">
            <Divider height="2px" color="rgb(255, 0, 0,0.2)"/>
          </div>
        
          <div className="row mt-4 gap-3 gap-lg-0">
                                            
            <div className="col-lg order-0 order-lg-1">

              <div> 
                <ReportSummary total={total.current}/> 
              </div>
              
            </div> 

          </div>
        </>
        :
        null
      } 
  
    </div>
    
  );
};

export default SummaryReportByTch;


