import React, { useState } from "react";
import { Modal,Form} from 'react-bootstrap'; 
import Button from '@mui/material/Button';
import "react-datepicker/dist/react-datepicker.css";
import './popup_modals.css';
import { FaChalkboardTeacher } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../Components/search_by_date.css'

const AcceptBtn=(props)=>{ 
    
    const [show,setShow]=useState(false);
    const [validated, setValidated] = useState(false);   
    const [comment,setComment] = useState(""); 
    const [expDate, setExpDate] = useState(new Date());    
        
//-------------------------------------------------------------------------------------------

    const handleChange = event => {
        setComment(event.target.value)        
    }
//------------------------------------------------------------------------------------------- 

    const handleSubmit = async (event) => {
        
        const form = event.currentTarget
                        
        event.preventDefault()
        event.stopPropagation()
                     
        if (form.checkValidity()){
            props.confirm(comment,expDate)
            closeConfirmationBox()                                                                        
        }
        else
            setValidated(true)        
    }
//------------------------------------------------------------------------------------------- 

    const openConfirmationBox = () => { 
        setShow(true);
    }
//------------------------------------------------------------------------------------------- 

    const closeConfirmationBox = () => { 
          setShow(false);        
    }
//------------------------------------------------------------------------------------------- 

    return (  
        <div>        
            
            {props.status!=="APPROVED"?
                <button type="button" class="btn btn-outline-success btn-sm" style={{borderRadius:"15px",width:"85px"}} onClick={openConfirmationBox}>מאשר</button>
                :
                <button type="button" class="btn btn-outline-success btn-sm" style={{borderRadius:"15px",width:"85px"}} disabled>מאשר</button>
            }

            <Modal 
              show={show} 
              onHide={closeConfirmationBox} 
              size="md"
              aria-labelledby="contained-modal-title-vcenter"            
             >  

                <Modal.Header closeButton>
                    <div className="d-flex gap-2 align-items-center">
                        <div><FaChalkboardTeacher size={30} /></div>
                        <div className="mt-1"><h4>{props.title}</h4></div>
                    </div>                                              
                </Modal.Header>  
                
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body>

                        <div className="label-100-wrap mt-3">                                           
                            <label className="form-label label-100" for="comment">הערות</label>                                                                                           
                            <div>                    
                                <textarea class="form-control" id="comment" name="comment" rows="3" value={comment} onChange={handleChange} required></textarea>        
                            </div>   
                        </div> 
                                                                                           
                        {props.exp?
                            <div className="label-100-wrap mt-4">
                                <label className="form-label label-100" for="exp_date">תאריך פקיעת תוקף</label>
                                <div id="exp_date" dir="rtl">              
                                    <DatePicker 
                                        wrapperClassName="date-picker" 
                                        selected={expDate} 
                                        onChange={(date) => setExpDate(date)} 
                                        popperPlacement="bottom-end" 
                                        dateFormat="yyyy-MM-dd"                                                 
                                    />                      
                                </div>
                            </div>
                            :
                            null
                        }
                                                                                                                                               
                    </Modal.Body>  

                    <Modal.Footer>
                        <div className="d-flex gap-3">                         
                            <Button variant="contained" color="error"   size="small" onClick={closeConfirmationBox}>ביטול</Button>  
                            <Button variant="contained" color="success" size="small" type="submit">שמירה</Button>                         
                        </div>
                    </Modal.Footer> 

                </Form>        
            </Modal> 
        </div>        
    )  
  } 
  
export default AcceptBtn;  
