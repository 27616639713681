import React, { useState} from "react";
import { Modal} from 'react-bootstrap'; 
import Button from '@mui/material/Button';
import { VscFiles } from "react-icons/vsc"
import TchProfileCard from "./tch_profile_card";
import DocsForProfile from "./docs_for_profile";

const ProfileViewer=(props)=>{ 
 
  const [show,setShow]=useState(false);
  
  const closeProfileBox = () => {         
    setShow(false)    
  }
//-------------------------------------------------------------------------------------------

  const openProfileBox = () => {

    setShow(true) 

  }
//-------------------------------------------------------------------------------------------

  return (  
      <div>
        
        <a href="#"  onClick={openProfileBox}>{props.btn_txt}</a>                      
          
        <Modal 
              show={show} 
              onHide={closeProfileBox} 
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"                         
        >  
          <Modal.Header closeButton>
            <div className="d-flex gap-2 align-items-center">
              <div><VscFiles size={30} /></div>
              <div className="mt-2"><h4>כרטיס מורה/מרכז לימוד</h4></div>
            </div>
          </Modal.Header>
         
          <Modal.Body>    
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <div className="d-flex justify-content-center justify-content-lg-start" >
                    <TchProfileCard userId={props.userId} user={props.user} />
                  </div>
                </div>

                <div className="col-lg-7">
                  <DocsForProfile userId={props.userId} user={props.user} adminId={props.adminId} />
                </div>

              </div>
              
            </div>                        
                                                                  
          </Modal.Body>
            
          <Modal.Footer>                           
            <div>            
              <Button variant="contained"  color="error"  size="small" onClick={closeProfileBox}>סגור</Button>              
            </div>
          </Modal.Footer> 

        </Modal> 
      </div>        
    )  
  } 
  
export default ProfileViewer;  
