import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import useCurrencyRate from "./use_currency_rate";

const PayPal = (props) => {
    const { rate, isLoading, error } = useCurrencyRate(process.env.REACT_APP_CURRENCY);

    if (isLoading) return <p>Loading currency rate...</p>;
    if (error) return <p>Error fetching currency rate: {error.message}</p>;

    const createOrder = (data, actions) => {
        
        if (!rate) {
            console.error("Currency rate is not available.");
            return;
        }

        return actions.order.create({
            purchase_units: [
                {
                    description: "Online lessons from DoroosOnline",
                    amount: {
                        currency_code: 'USD',
                        value: (props.total / rate).toFixed(2),
                    },
                },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING",
            },
        }).then((orderID) => {
            return orderID;
        });
    };

    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const transactionId = details.id;
            props.toPaidStatus({
                method: "paypal",
                last4digits: "0000",
                confirmationCode: transactionId
            });
        });
    };

    const onError = (err) => {
        const errorMessage = err.message || "An unknown error occurred.";
        props.setErrorMsg(errorMessage, "Error");
    };

    return (
        <PayPalScriptProvider options={{"client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID}}>
            <div>
                {rate && (
                    <PayPalButtons
                        key={props.total}
                        style={{ layout: "vertical", label: "checkout" }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onError={onError}
                        disabled={props.total <= 0}
                    />
                )}
            </div>
        </PayPalScriptProvider>
    );
};

export default PayPal;
