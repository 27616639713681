import './custom_arrows.css'

const CustomPrevArrow = (props) => {
    const {className, onClick} = props
    
    return (
      <div className={className} onClick={onClick}>
        <i className="fa fa-arrow-right"></i>
      </div>
    );
};
export default CustomPrevArrow;
  
