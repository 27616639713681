import React, { useState} from "react";
import { Modal} from 'react-bootstrap'; 
import Button from '@mui/material/Button';
import { VscFiles } from "react-icons/vsc"
import ReactLoading from 'react-loading';
import { storage } from "./firebase";
import { ref,getDownloadURL} from "firebase/storage";

const DocViewer=(props)=>{ 
 
  const [show,setShow]=useState(false);
  const [fire,setFire]=useState(false);
  const [url,setUrl]=useState("");
  
  const closeDocBox = () => {         
    setShow(false)    
  }
//-------------------------------------------------------------------------------------------

  const openDocBox = () => {

    setShow(true) 

    if(props.doc!==""){      
      const fileRef=ref(storage,`docs/${props.doc}`)
      getDownloadURL(fileRef).then((url)=>{
        setUrl(url)
        setFire(true)
      }).catch((error)=>{
        alert(error)     
      }) 
    }      

  }
//-------------------------------------------------------------------------------------------

  return (  
      <div>
        {props.doc!==""?
          <a href="#"  onClick={openDocBox}>{props.btn_txt}</a>                      
          :
          <button type="button" class="btn btn-link" disabled>{props.btn_txt}</button>                      
        }
        <Modal 
              show={show} 
              onHide={closeDocBox} 
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"                         
        >  
          <Modal.Header closeButton>
            <div className="d-flex gap-2 align-items-center">
              <div><VscFiles size={30} /></div>
              <div className="mt-2"><h4>מציג מסמכים</h4></div>
            </div>
          </Modal.Header>
         
          <Modal.Body>    
                                    
            <div className="position-relative" style={{height:"400px",borderColor:"lightgray", borderStyle:"solid",borderWidth:"thin"}}>
              {fire?
                <div className="position-absolute top-50 start-50 translate-middle-x" style={{zIndex: "1",top:"300",left:'300'}}>
                  <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
                </div>
                :
                null
              } 

              <embed 
                type="application/pdf" 
                src={url} 
                width={100+'%'} 
                height={100+'%'} 
                onLoad={()=>{setTimeout(()=>{setFire(false) },1000)}}
              />
              
            </div>
                         
          </Modal.Body>
            
          <Modal.Footer>                           
            <div className="d-flex gap-3 me-3 mb-2">            
              <Button variant="contained"  color="error"  size="small" onClick={closeDocBox}>סגור</Button>              
            </div>
          </Modal.Footer> 

        </Modal> 
      </div>        
    )  
  } 
  
export default DocViewer;  
