import React, {useState,useEffect} from "react";
import "react-datepicker/dist/react-datepicker.css";
import PayPal from "./paypal";
import './popup_modals.css';
import './checkout.css';
import CreditCard from "./credit_card";

const Checkout=(props)=>{ 

  const [method,setMethod]=useState(null);

  useEffect(()=>{
    setMethod(props.method)
  },[props.method])
  
  
//-------------------------------------------------------------------------------------------  

    return (  
      <div>
        
        {method==="visa"?      
          <CreditCard 
            toPaidStatus={props.toPaidStatus} 
            total={props.total}
            subTotal={props.subTotal}
            userId={props.userId}
            set_show_overlay={props.set_show_overlay}
            setErrorMsg={props.setErrorMsg}
            prepareReceiptDtls={props.prepareReceiptDtls}
          />
          :
          <PayPal 
            toPaidStatus={props.toPaidStatus} 
            total={props.total}
            setErrorMsg={props.setErrorMsg}
          />
        }
        
      </div>        
    )  
  } 
  
export default Checkout;  
