
import React, { useEffect, useState } from "react";
import '../Components/box.css'
import noIcon from '../Assets/Images/math3.png'

const InvoiceLine = (props) =>{

  useEffect(()=>{   
    props.updateTotal(props.amount*props.price)
    
  })
//-------------------------------------------------------------------------------------------
    
  return (
           
    <div class="item-box" style={{background:props.bgcolor}}>                                                      
      <div className="d-flex flex-wrap align-items-center justify-content-around" > 

        <div class="d-flex flex-column align-items-center px-3 py-2 py-lg-0">            
          <span><b>{props.index+1}</b></span>
        </div>

        <div className="px-3 py-2 py-lg-0">                                    
          <span className="course-img">
            {props.photo!=null?
              <img src={props.photo}></img>
              :
              <img src={noIcon}></img>
            }
          </span>                                           
        </div>
    
        <div class="lesson-sub d-flex flex-column align-items-center px-3 py-2 py-lg-0">
            <div class="subject">
              {props.subject}
            </div>                                        
        </div>

        <div class="d-flex flex-column align-items-center gap-1 px-3 py-2 py-lg-0">
            <span class="date">{props.date}</span>
            <span>{props.end} - {props.start}</span>
        </div>

        <div class="d-flex flex-column align-items-center gap-2 px-3 py-2 py-lg-0">
            <span className="teacher">{props.teacher}</span>                       
        </div>

        <div class="d-flex flex-column align-items-center gap-2 px-3 py-2 py-lg-0">
            <span className="price">{props.price}</span>               
        </div>

        <div class="d-flex flex-column align-items-center gap-2 px-3 py-2 py-lg-0">
            <span className="price">{props.amount}</span>               
        </div>

        <div class="d-flex flex-column align-items-center gap-2 px-3 py-2 py-lg-0">
            <span className="price">{props.amount*props.price}</span>               
        </div>

      </div>                                                  
    </div>                                
  );
};

export default InvoiceLine;
