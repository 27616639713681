import React, { useState,useEffect } from "react";
import Rating from '@mui/material/Rating';

const BasicRating=(props)=> {
  const [rating, setRating] = useState(0);
  
  
  useEffect(()=>{

    if(props.ratings!==0)
      setRating(props.value/props.ratings)
    else
      setRating(0)

  },[props.ratings,props.refresh]);
//-------------------------------------------------------------------------------------------

  return (
    
    <div className="d-flex flex-wrap gap-1 align-items-center">
      {props.ratings>0?<span style={{fontSize:"0.7em"}}>{rating.toLocaleString(undefined, {maximumFractionDigits:2})}</span>:null}
      <div className="mt-1">
        {rating===0 && !props.readOnly?
          <Rating
            name="simple-controlled"
            size="small" 
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
              props.updateRating(newValue);
            }}
          />
          :
          <Rating
            name="simple-controlled"
            size="small"           
            precision={0.5}
            value={rating}
            readOnly
          />
        }
      </div>
      {props.ratings>0?<span style={{fontSize:"0.7em"}}>{props.ratings}</span>:null}
    </div>
  );
}

export default BasicRating;
