import { useState } from "react";
import {useNavigate} from 'react-router-dom';
import SignIn from "../Components/signin";
import ReactLoading from 'react-loading';

const AdminLogin = (props) => {

  const [fire,setFire]=useState(false);
  const navigate = useNavigate();

  const turnFireOn=()=> setFire(true);
  const turnFireOff=()=>setFire(false);
      
  const connect=()=>{
    window.sessionStorage.setItem("user","ADMIN");
    navigate("/");
    props.connect();
  }
  
  return(
    <>

      <div className="d-flex flex-column align-items-center mt-5 mb-5" style={{minHeight:"350px"}}>

        <div className='login-width'>
          <span className="titleStyle">כניסה לחשבון</span>
        </div>             
        
        <div className="d-flex flex-column shadow gap-3 p-4 position-relative login-width">          

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex:"1"}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          }   

          <div>
            <SignIn 
              user="ADMIN" 
              connect={connect}
              turnFireOn={turnFireOn}
              turnFireOff={turnFireOff} 
            />
          </div> 
                                    
        </div> 

        <div className='mt-3'>
          <span>www.doroosonline.co.il</span>
        </div>

      </div>
    </>
                                
  )};
  
export default AdminLogin;
