import React, { useState } from "react";
import { Modal,Form} from 'react-bootstrap'; 
import Button from '@mui/material/Button';
import "react-datepicker/dist/react-datepicker.css";
import './popup_modals.css';
import { FaChalkboardTeacher } from 'react-icons/fa';

const RejectBtn=(props)=>{ 
    
    const [show,setShow]=useState(false);
    const [validated, setValidated] = useState(false);   
    const [comment,setComment] = useState("");     
        
//-------------------------------------------------------------------------------------------

    const handleChange = event => {
        setComment(event.target.value)        
    }
//------------------------------------------------------------------------------------------- 

    const handleSubmit = async (event) => {
        
        const form = event.currentTarget
                        
        event.preventDefault()
        event.stopPropagation()
                     
        if (form.checkValidity()){
            props.deny(comment,"2022-12-03")
            closeRejectionBox()                                                                        
        }
        else
            setValidated(true)        
    }
//------------------------------------------------------------------------------------------- 

    const openRejectionBox = () => { 
        setShow(true);
    }
//------------------------------------------------------------------------------------------- 

    const closeRejectionBox = () => { 
          setShow(false);        
    }
//------------------------------------------------------------------------------------------- 

    return (  
        <div>        
            
            {props.status!=="NOT_APPROVED"?
                <button type="button" class="btn btn-outline-danger btn-sm" style={{borderRadius:"15px",width:"85px"}} onClick={openRejectionBox}>לא מאשר</button>
                :
                <button type="button" class="btn btn-outline-danger btn-sm" style={{borderRadius:"15px",width:"85px"}} disabled>לא מאשר</button>
            }

            <Modal 
              show={show} 
              onHide={closeRejectionBox} 
              size="md"
              aria-labelledby="contained-modal-title-vcenter"            
             >  

                <Modal.Header closeButton>
                    <div className="d-flex gap-2 align-items-center">
                        <div><FaChalkboardTeacher size={30} /></div>
                        <div className="mt-1"><h4>{props.title}</h4></div>
                    </div>                                              
                </Modal.Header>  
                
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body> 
                                                                                           
                        <div className="label-100-wrap mt-3">                                           
                            <label className="form-label label-100" for="comment">הערה</label>                                                                                           
                            <div>                    
                                <textarea class="form-control" id="comment" name="comment" rows="3" value={comment} onChange={handleChange} required></textarea>        
                            </div>   
                        </div> 
                                              
                    </Modal.Body>  

                    <Modal.Footer>
                        <div className="d-flex gap-3">                         
                            <Button variant="contained" color="error"   size="small" onClick={closeRejectionBox}>ביטול</Button>  
                            <Button variant="contained" color="success" size="small" type="submit">שמירה</Button>                         
                        </div>
                    </Modal.Footer> 

                </Form>        
            </Modal> 
        </div>        
    )  
  } 
  
export default RejectBtn;  
