import  React, {useState,useEffect,useRef} from "react";
import {useLocation} from 'react-router-dom';
import PageTitle from "../Components/page_title";
import Pagination from '@mui/material/Pagination';
import SearchByDates from "../Components/search_by_date";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import UserLessonBox from "../Components/user_lesson_box";
import NewLesson from "../Components/new_lesson"; 
import axios from 'axios';
import { addMinutes } from "../Components/time";
import ReactLoading from 'react-loading';
import { Box,Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

const baseUrl = process.env.REACT_APP_BASE_URL; 
const win=4;

const SchTchClassRoom = (props) => {

  const [page, setPage] = useState(1);
  const [lessons,setLessons]=useState([]);
  const [refresh,setRefresh]=useState(false);
  const [newLesson,setNewLesson]=useState(false);
  const location = useLocation();
  const [screenHeight, setScreenHeight] = useState(0);
  const [fire,setFire]=useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [lessonsStatus,setLessonStatus]= useState("TODAY");
  const isInitialMount = useRef(true);

  const [courseDetails, setCourseDetails] = useState({
    userId: null,
    code: null,
    user: null,
    price:null,
    mode:null,
    xcode:null,
    enabled:false,
  });
//-------------------------------------------------------------------------------------------

  const fetchLessons = async (details) => {
    
    try {

      setFire(true)
     
      const response = await axios.get(`${baseUrl}/tch_lessons_by_course.php`,{
        params: {                        
          userId: details.userId,
          user: details.user,
          code: details.code,
          filter:lessonsStatus,                                                                      
        }
      })
     
      if(Array.isArray(response.data) && response.data.length){
        setLessons(response.data)
        setRefresh(prev=>prev=!prev)
      }
      else{
        setLessons([]) 
      } 
      setFire(false)
    } 
    catch (error) {

      alert(error) ;
      
    }

    setPage(1) 
    goToTop()
    
  }
//-------------------------------------------------------------------------------------------
  
  useEffect(() => {

    let details;

    details = {
      userId: props.userId,
      code: location.state.code,
      user: location.state.user,
      price:location.state.price,
      mode:location.state.mode,
      xcode:location.state.xcode,
      enabled:props.enabled,
    };
   
    setCourseDetails(details);
    fetchLessons(details);

    setScreenHeight(window.innerHeight - 200);

  }, [newLesson]);
//-------------------------------------------------------------------------------------------

  useEffect(() => {
    if(lessonsStatus!=="UNDEFINED")
      if (isInitialMount.current) {
          isInitialMount.current = false;
      } else {
          fetchLessons(courseDetails);
      }
  }, [lessonsStatus]);
//-------------------------------------------------------------------------------------------

  const set_show_overlay= (value)=>{
    setShowOverlay(value);
  }
//-------------------------------------------------------------------------------------------
      
  const refreshPage = () => {
    setNewLesson(prev=>prev=!prev)
    setRefresh(prev=>prev=!prev) 
  }
//-------------------------------------------------------------------------------------------

  const fetchLessonsByDates = async (sDate,eDate) => {
    try {
      setFire(true)
      const response = await axios.get(`${baseUrl}/tch_lessons_by_dates_and_course.php`,{
        params: {                        
          userId: courseDetails.userId,
          user: courseDetails.user,
          code: courseDetails.code,
          sDate:sDate,
          eDate:eDate,                                                          
        }
    })
      if(Array.isArray(response.data) && response.data.length){
        setLessons(response.data)
        setRefresh(prev=>prev=!prev)
      }
      else{
        setLessons([])
      }

      setLessonStatus("UNDEFINED")
      setFire(false)
    } 
    catch (error) {

      alert(error) ;
      
    }
    setPage(1)
    goToTop()
  }
//-------------------------------------------------------------------------------------------

  const goToTop = () => {
    window.scrollTo({
        top:0,
        behavior: 'auto',
    })
  }
//-------------------------------------------------------------------------------------------
  const handleChangePage = (_,page)=> {
        
    if (page !== null){
      setPage(page) 
      setRefresh(prev=>prev=!prev)     
      goToTop()
    }
  
  }
//-------------------------------------------------------------------------------------------

  const updateLessonDtls=(lesson,index)=>{

    let x=(page-1)*win+index;
    let temp_state = [...lessons];
    let temp_element = { ...temp_state[x]};

    if(temp_element.lessonId!=null){
      temp_element.subject =lesson.sub; 
      temp_element.date = lesson.date;   
      temp_element.num = lesson.num;      
      temp_element.start = lesson.start; 
    }
    
    temp_state[x] = temp_element;
    setLessons(temp_state);
    setRefresh(prev=>prev=!prev);

  }
//-------------------------------------------------------------------------------------------

  const updateLessonSubject=(subject,index)=>{
    
    let x=(page-1)*win+index;
    let temp_state = [...lessons];
    let temp_element = { ...temp_state[x]};
    
    if(temp_element.lessonId!=null){
      temp_element.subject = subject;     
    }
    else{
      temp_element.p_subject = subject;     
    }
        
    temp_state[x] = temp_element;
    setLessons(temp_state);

  }
//-------------------------------------------------------------------------------------------

  return (
    
    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>

        {showOverlay && 
          <Box position="fixed" top={0} left={0} width="100%" height="100%" bgcolor="rgba(0,0,0,0.5)" zIndex={9999}></Box>
        }
                      
        <div className="mt-4">
          <PageTitle  text="מפגשים"/>
        </div>
        
        <div className="mt-5 ms-2">
          <SearchByDates setDateRange={fetchLessonsByDates}/>
        </div>
        
        <div className="mt-4">          
          <Divider>
            <Chip 
              label={location.state.subject}  
              size="small" 
            />
          </Divider>
        </div> 
        
        <div className="d-flex flex-wrap gap-3 mt-3">
          <Button 
            variant="contained" 
            color="secondary" 
            size="small" 
            disabled={lessonsStatus === "TODAY"}
            onClick={()=>setLessonStatus("TODAY")}
          >
            השיעורים של היום
          </Button>

          <Button 
            variant="contained" 
            color="secondary" 
            size="small" 
            disabled={lessonsStatus === "FUTURE"}
            onClick={()=>setLessonStatus("FUTURE")}
          >
            שיעורים עתידיים
          </Button>

          <Button 
            variant="contained" 
            color="secondary" 
            size="small" 
            disabled={lessonsStatus === "HISTORY"}
            onClick={()=>setLessonStatus("HISTORY")}
          >
            היסטוריית שיעורים
          </Button>

          {courseDetails.mode==='GROUP' && courseDetails.user==='TCH'?
            <NewLesson 
              link={true} 
              tchId={courseDetails.userId} 
              code={courseDetails.xcode} 
              price={courseDetails.price} 
              refreshPage={refreshPage}
              enabled={courseDetails.enabled}
            />
            :
            null
          }

        </div>
                
        <div className="d-flex flex-column position-relative gap-3 mt-2">

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex: "1"}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          }  

          {lessons.slice((page-1)*win,(page-1)*win+win).map((l,index)=>(                                                                                              
            <div>              
              <UserLessonBox                                 
                index={index}
                user={courseDetails.user}
                bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"} 
                teacher={l.lessonId!=null?l.teacher:l.p_teacher} 
                rating={l.lessonId!=null?l.rating:l.p_rating} 
                ratings={l.lessonId!=null?l.counter:l.p_rating>0?1:0}
                subject={l.lessonId!=null?l.subject:l.p_subject} 
                date={l.lessonId!=null?l.date:l.p_date}
                start={l.lessonId!=null?l.start:l.p_start}  
                lessons_num={l.lessonId!=null?l.num:l.p_num}
                end={l.lessonId!=null?addMinutes(l.start,l.num*50):addMinutes(l.p_start,l.p_num*50)} 
                price={l.lessonId!=null?l.price*l.num:l.p_price*l.p_num}  
                photo={l.photo} 
                tchId={l.lessonId!=null?l.tchId:l.p_tchId}
                userId={props.userId}
                code={l.code }
                lessonId={l.lessonId!=null?l.lessonId:l.p_lessonId}                             
                recording={l.lessonId!==null?l.recording:l.p_recording}
                done={l.lessonId!==null?l.done:l.p_done}
                cancelled={l.lessonId!==null?l.cancelled:l.p_cancelled}
                cal={l.lessonId!=null?l.CAL:l.TCAL}                                                                                            
                updateLessonDtls={updateLessonDtls} 
                refresh={refresh}  
                mode={l.lessonId!=null?'GROUP':'PRIVATE'}  
                platform={l.lessonId!=null?l.platform:l.p_platform}              
                updateLessonSubject={updateLessonSubject} 
                set_show_overlay={set_show_overlay}                      
              />
            </div>            
          ))} 

        </div>
        
        <div  class="d-flex justify-content-end mt-4" dir="ltr">                     
          <Pagination count={Math.ceil(lessons.length/win)} variant="outlined" shape="rounded" color="primary" page={page} onChange={handleChangePage}/>         
        </div>

    </div>
    
  );
};

export default SchTchClassRoom;
