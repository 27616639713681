import React, { useState } from "react";
import '../Components/page_title.css';

const PageTitle=(props)=>{ 
    
    return ( 
        <div>
             <span className={`divider ${props.sub?"gradient_2":"gradient"}`}>{props.text}</span>
        </div> 
       
    )  
  } 
  
export default PageTitle;  
