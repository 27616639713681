import React, {useState,useEffect} from "react";
import PageTitle from "../Components/page_title";
import Pagination from '@mui/material/Pagination';
import SearchByDates from "../Components/search_by_date";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import axios from 'axios';
import Divider from '@mui/material/Divider';
import ReactLoading from 'react-loading';
import MessageBox from "../Components/message_box";

const baseUrl = process.env.REACT_APP_BASE_URL; 
const win=4;

const Messages = (props) => {
  const [page, setPage] = useState(1); 
  const [messages,setMessages]=useState([]); 
  const [screenHeight, setScreenHeight] = useState(0);
  const [fire,setFire]=useState(false);

  useEffect(() => {
    setScreenHeight(window.innerHeight-200);
  }, []);
//-------------------------------------------------------------------------------------------
    
  useEffect(()=>{

    const fetchMessages = async () => {
      try {
        setFire(true)
        
        var response = await axios.get(`${baseUrl}/get_messages.php`)
                
        if(Array.isArray(response.data) && response.data.length){
          setMessages(response.data)
          setFire(false)
        }
        else{
          setMessages([])
          setFire(false)
        }  
      } 
      catch (error) {

        alert(error) ;
        
      }
      
    }

    fetchMessages();
        
  },[]);
//-------------------------------------------------------------------------------------------

  const fetchMessagesByDates = async (sDate,eDate) => {
    try {

      setFire(true)      
      
        var response = await axios.get(`${baseUrl}/get_messages_by_dates.php`,{
          params: {                                     
            sDate:sDate,
            eDate:eDate                                                                     
          }
        })
      
      if(Array.isArray(response.data) && response.data.length){
        setMessages(response.data)
        setFire(false)
      }
      else{
        setMessages([])
        setFire(false)
      }  
    } 
    catch (error) {

      alert(error) ;
      
    }

    setPage(1)
      
  }
//-------------------------------------------------------------------------------------------

  const goToTop = () => {
    window.scrollTo({
        top:0,
        behavior: 'auto',
    })
  }
//-------------------------------------------------------------------------------------------

  const handleChangePage = (_,page)=> {
        
    if (page !== null){
      setPage(page);
      goToTop()
    }
  }
//-------------------------------------------------------------------------------------------

  const updateMsgStatus=(index,status)=>{

    let x=(page-1)*win+index;
    let temp_state = [...messages];
    let temp_element = { ...temp_state[x]};
    temp_element.status =status;
    temp_state[x] = temp_element;
    setMessages(temp_state);
  }
//-------------------------------------------------------------------------------------------

  const updateMessage=(index,msg)=>{

    let x=(page-1)*win+index;
    let temp_state = [...messages];
    let temp_element = { ...temp_state[x]};
    temp_element.msg =msg;
    temp_element.status ='Sent';
    temp_state[x] = temp_element;
    setMessages(temp_state);
  }
//-------------------------------------------------------------------------------------------

  return (
    
    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>
                      
        <div className="mt-4">
          <PageTitle text="הודעות"/>
        </div>
                
        <div className="mt-5 ms-2">
          <SearchByDates setDateRange={fetchMessagesByDates}/>
        </div>
                
        <div className="mt-4">          
          <Divider></Divider>
        </div>
        
        
        <div className="d-flex flex-wrap gap-2 mt-4 position-relative">

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex: "1"}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          }
 
          {messages.slice((page-1)*win,(page-1)*win+win).map((m,index)=>(                                                                                               
            <div style={{width:"100%"}}>
              <MessageBox  
                index={index}
                msgId={m.id}
                bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"} 
                subject={m.subject} 
                name={m.firstName+" "+m.lastName}             
                msg={m.msg}
                date={m.msg_date}  
                email={m.email}   
                mobile={m.mobile} 
                status={m.status}
                decreaseMessagesCount={props.decreaseMessagesCount} 
                updateMsgStatus={updateMsgStatus} 
                updateMessage={updateMessage} 
                page={page}                                                                                                                                                                                                                                                             
              />             
            </div>
          ))}
                             
        </div>
        
        <div  class="d-flex justify-content-end mt-4" dir="ltr">                     
          <Pagination count={Math.ceil(messages.length/win)} variant="outlined" shape="rounded" color="primary" page={page} onChange={handleChangePage}/>         
        </div>

    </div>
    
  );
};

export default Messages;
