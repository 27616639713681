const Line=(props)=> {

  return (

    <div style={{
            borderBottomStyle:"double",
            borderBlockColor:props.color,
            borderWidth:props.height,
            width:"100%"
        }}>
    </div>
     
  );
} 

const Divider = (props) => {
  const isTextEmpty = !props.text || props.text === "";

  return (
    <div className="d-flex align-items-center" style={{ width: "100%" }}>
      {isTextEmpty ? (
        <Line color={props.color} height={props.height} />
      ) : (
        <>
          
          <div style={{ flex: 1 }}>
            <Line color={props.color} height={props.height} />
          </div>
         
          <div style={{ padding: "0 10px" }}>
            <span style={{color:props.textColor}}>{props.text}</span>
          </div>
          
          <div style={{ flex: 1 }}>
            <Line color={props.color} height={props.height} />
          </div>

        </>
      )}
    </div>
  )
}
export default Divider;  
