import React, { useState } from "react";
import DatePicker from "react-datepicker";
import dateFormat, { masks } from "dateformat";
import { addDays } from "./time"
import "react-datepicker/dist/react-datepicker.css";
import '../Components/search_by_date.css'
import styled from 'styled-components';

const StyledDatePickerWrapper = styled.div`
    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    input {
        width: 130px; // Adjust as needed
    }
`;

const SearchByDates=(props)=>{ 
   
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const setDateRange = () => {
        masks.hammerTime = 'yyyy-mm-dd';
        props.setDateRange(dateFormat(startDate, "hammerTime"),dateFormat(endDate, "hammerTime"));     
    }
//-------------------------------------------------------------------------------------------
        
    return (  
                
        <div className="d-flex flex-wrap gap-3 align-items-center"> 
            
            <div className="label-100-wrap">
                <label className="form-label label-100" for="start_date">מתאריך</label>
                <div id="start_date" dir="ltr">  
                    <StyledDatePickerWrapper>
                        <DatePicker 
                            wrapperClassName="date-picker" 
                            selected={startDate} 
                            onChange={(date) => setStartDate(date)} 
                            popperPlacement="bottom-end" 
                            dateFormat="yyyy-MM-dd" 
                            minDate={props.minDate >= 0 ? addDays(new Date(), props.minDate) : undefined}                                                
                        />  
                    </StyledDatePickerWrapper>                    
                </div>
            </div>

            <div className="label-100-wrap">
                <label className="form-label label-100" for="end_date">עד תאריך</label>
                <div id="end_date" dir="ltr"> 
                    <StyledDatePickerWrapper>                                                           
                        <DatePicker 
                            wrapperClassName="date-picker"  
                            selected={endDate} 
                            onChange={(date1) => setEndDate(date1)}
                            popperPlacement="bottom-end"
                            dateFormat="yyyy-MM-dd" 
                            minDate={props.minDate >= 0 ? addDays(new Date(), props.minDate) : undefined}  
                        /> 
                    </StyledDatePickerWrapper>                                  
                </div>
            </div>

            <div>            
                <button class="btn btn-primary btn-sm" style={{borderRadius:"20px",width:"70px"}} onClick={setDateRange} >הצג</button>
            </div>                                                                              
            
        </div> 
                          
    )  
  } 
  
export default SearchByDates;  
