import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 95,
    top: 2,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const DocsBadge = (props) => {
  return (
    <>
      {props.count > 0 && (
        <StyledBadge badgeContent={props.count} color="secondary">
          <span>אישור מסמכים</span>
        </StyledBadge>
      )}
      {props.count <= 0 && <span>אישור מסמכים</span>}
    </>
  );
}

export default DocsBadge;
