import React, { useState } from "react";
import { login } from "./db";
import * as Yup from 'yup';
import '../Components/forms.css'

const loginSchema = Yup.object().shape({
  email: Yup.string() 
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
});
//-------------------------------------------------------------------------------------------

const SignIn=(props)=> {
  const [errors, setErrors] = useState({});
  const [userDtls, setUserDtls] = useState({  
    email:'', 
    password:''
  });
//-------------------------------------------------------------------------------------------

  const handleChange = e => {
      const { name, value } = e.target;
      setUserDtls(prevState => ({
          ...prevState,
          [name]: value
      }));
  };
//-------------------------------------------------------------------------------------------

  const setItemWithExpiration = (key,data) => {
    const now = new Date()
    
    const currentTime= now.getTime();

    const item = {
      data: data,
      startActivity: currentTime, 
      lastActivity : currentTime,
    }
    
    window.localStorage.setItem(key, JSON.stringify(item));
  }
//-------------------------------------------------------------------------------------------
     
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
        await loginSchema.validate(userDtls, { abortEarly: false });
                                
        const password = userDtls.password;      
        const email    = userDtls.email;      
        const auth     ="NORMAL";
        const user=props.user;

        try {
          
          props.turnFireOn()

          const [id,userId,enabled,blocked]= await login(user,auth,email,password)

          if (id > 0 && userId > 0) {

            const loginData = {
              isLoggedIn: true,
              userID: userId,
              id: id,
              email: email,
              auth: auth,
              enabled: enabled,
              blocked: blocked,
              user:user,
            };
  
            setItemWithExpiration('loginData', loginData);
            props.connect();

          } else {
            throw new Error("Login failed. Please try again.");
          }

        } catch (error) {
          alert(error.message);
        } finally {
          props.turnFireOff() 
        }
        
        setUserDtls({email:'',password:''});
        setErrors({});

    } catch (err) {
    
      setErrors(err.inner.reduce((acc, { path, message }) => {
        acc[path] = message;
        return acc;
      }, {}));
    }
  }
//-------------------------------------------------------------------------------------------
  
  return (
    
    <form onSubmit={handleSubmit}>
      <div className="d-flex flex-column gap-3">  
                                
        <div>
            <input  
              type="text" 
              className={`form-control ${errors.email? 'invalid' : ''}`} 
              name="email" 
              value={userDtls.email} 
              onChange={handleChange} 
              placeholder="דואר אלקטרוני"
            />
            {errors.email && <p className="error">{errors.email}</p>}
        </div>     
                                          
        <div>
            <input 
              type="password" 
              className={`form-control ${errors.password? 'invalid' : ''}`}
              name="password" 
              value={userDtls.password} 
              onChange={handleChange} placeholder="סיסמה"
            /> 
            {errors.password && <p className="error">{errors.password}</p>}
        </div>   
        
        <div>
          {props.disabled?
            <button type="submit" class="btn btn-outline-secondary btn-sm" style={{width:"100%",height:"40px"}} disabled>כניסה</button>
            :
            <button type="submit" class="btn btn-outline-secondary btn-sm" style={{width:"100%",height:"40px"}}>כניסה</button>
          }
        </div>                   
                                                                                                                                                          
      </div> 
    </form>     
  );
}

export default SignIn;
