import { useState,useEffect } from "react";
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const SelectCategory=(props)=>{
    
  const [categories,setCategories]=useState([])
  const [cat,setCat]=useState(-1)

  useEffect(()=>{

      const fetchCategories = async () => {
        try {
          const response = await axios.get(`${baseUrl}/categories.php`)
          
          if(response.data.length>0){
            setCategories(response.data)
            if(!props.editing)
              props.setCategory(response.data[0].id)
          }                                                                                                          
          else            
            setCategories([]) 
        } 
        catch (error) {
  
          alert(error) ;
          
        }
        
      }

      fetchCategories();
                          
  },[]);
//-------------------------------------------------------------------------------------------
    
  useEffect(()=>{

    setCat(props.catId)

  },[props.catId])
//-------------------------------------------------------------------------------------------

  return(

      <div>
          <select 
            class="form-select" 
            name="catigories" 
            value={cat} 
            onChange={(e)=>{
              setCat(e.target.value)
              props.setCategory(e.target.value)
            }}
          >                                           
              {
                  categories.map((c)=>(
                      <option value={c.id}>{c.name}</option>
              ))}                                                                                              
          </select> 
      </div>

  )}

export default SelectCategory;
