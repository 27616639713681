import React, { useState } from "react"; 
import { signup } from "./db";
import * as Yup from 'yup';
import '../Components/forms.css'

const loginSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Name must be at least 2 characters')
    .required('Name is required'),
  lastName: Yup.string()
    .min(2, 'Name must be at least 2 characters')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()    
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

const setItemWithExpiration = (key,data) => {
  const now = new Date()
  
  const currentTime= now.getTime();

  const item = {
    data: data,
    startActivity: currentTime, 
    lastActivity : currentTime,
  }
  
  window.localStorage.setItem(key, JSON.stringify(item));
}
//-------------------------------------------------------------------------------------------

const SignUp=(props)=> {
  const [errors, setErrors] = useState({});    
  const [userDtls, setUserDtls] = useState({  
    firstName:'',
    lastName:'',
    email:'',
    password:'',
    confirmPassword:'',
  });
//-------------------------------------------------------------------------------------------

  const handleChange = e => {
      const { name, value } = e.target;
      setUserDtls(prevState => ({
          ...prevState,
          [name]: value
      }));
  }
//-------------------------------------------------------------------------------------------

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      await loginSchema.validate(userDtls, { abortEarly: false });

      const password = userDtls.password;      
      const email    = userDtls.email;      
      const auth     ="NORMAL";
      const f_name= userDtls.firstName;
      const l_name= userDtls.lastName;
      const user=props.user;

      try{

        props.turnFireOn()       
        const [id, userId, enabled, blocked] = await signup(user, auth, email, password, f_name, l_name)
         
        alert('New user was created successfully !!')

        if (id > 0 && userId > 0) {

          const loginData = {
            isLoggedIn: true,
            userID: userId,
            id: id,
            email: email,
            auth: auth,
            enabled: enabled,
            blocked: blocked,
            user:user,
          };

          setItemWithExpiration('loginData', loginData);
          props.connect();
        }

      } catch( error ){
        alert( error.message )
      } finally {
        props.turnFireOff()
      }
        
      setUserDtls({firstName:'',lastName:'',email:'',password:'',confirmPassword:''});
      setErrors({});


    } catch (err) {
      
      setErrors(err.inner.reduce((acc, { path, message }) => {
        acc[path] = message;
        return acc;
      }, {}));
    }
  }  
//-------------------------------------------------------------------------------------------
  
  return (

    <form onSubmit={handleSubmit}>
      <div className="d-flex flex-column gap-3">  
                                
        <div className="d-flex gap-3">
          
          <div>
              <input 
                type="text" 
                className={`form-control ${errors.firstName? 'invalid' : ''}`}
                name="firstName" 
                value={userDtls.firstName} 
                onChange={handleChange} 
                placeholder="שם פרטי"
              />
               {errors.firstName && <p className="error">{errors.firstName}</p>}
          </div>   
                                            
          <div>
              <input 
                type="text" 
                className={`form-control ${errors.lastName? 'invalid' : ''}`} 
                name="lastName" 
                value={userDtls.lastName} 
                onChange={handleChange} 
                placeholder="שם משפחה" 
              /> 
              {errors.lastName && <p className="error">{errors.lastName}</p>}
          </div>

        </div>
                
        <div>
            <input  
              type="text" 
              className={`form-control ${errors.email? 'invalid' : ''}`} 
              name="email" 
              value={userDtls.email} 
              onChange={handleChange} 
              placeholder="דואר אלקטרוני"
            />
            {errors.email && <p className="error">{errors.email}</p>}
        </div>   
                                          
        <div>
            <input 
              type="password" 
              className={`form-control ${errors.password? 'invalid' : ''}`}
              name="password" 
              value={userDtls.password} 
              onChange={handleChange} placeholder="סיסמה"
            /> 
            {errors.password && <p className="error">{errors.password}</p>}
        </div>   

        <div> 
            <input 
              type="password" 
              className={`form-control ${errors.confirmPassword? 'invalid' : ''}`}                
              name="confirmPassword" 
              value={userDtls.confirmPassword} 
              onChange={handleChange} 
              placeholder="אימות סיסמה"
            />
            {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>} 
        </div>
        
        <div>
          {props.disabled?
            <button type="submit" class="btn btn-outline-secondary btn-sm" style={{width:"100%",height:"40px"}} disabled>הרשמה</button>
            :
            <button type="submit" class="btn btn-outline-secondary btn-sm" style={{width:"100%",height:"40px"}}>הרשמה</button>
          }
        </div>
                                                                                                                                                                  
      </div> 
    </form> 

  );
}

export default SignUp;
