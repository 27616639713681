
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close"; 
 
const MsgBox=(props)=>{ 
             
  const closeMsgBox = () => { 
    
      props.close();
  }
//-------------------------------------------------------------------------------------------    

  const action = () => { 
    closeMsgBox();
    props.onAction();
  }
//-------------------------------------------------------------------------------------------    

  return (  
    <div>              
      <Dialog
        open={true}
        keepMounted
        onClose={closeMsgBox}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-paper": {
            width: "500px", // Set the desired width
            borderRadius: "10px", // Set the desired border radius
          },
        }}
      >  
                  
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={closeMsgBox}
            aria-label="close"
            sx={{ position: "absolute",left: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          {props.title}
        </DialogTitle>

        <DialogContent>
          <DialogContentText
              id="alert-dialog-slide-description"
              sx={{
                textAlign: "right", // Align the content to the right
                whiteSpace: "pre-line",
              }}
          >
            {props.content}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          {!props.closeBtn?
            <div className="d-flex gap-3 me-3 mb-2">            
              <Button variant="contained"  color="error"  size="small" onClick={closeMsgBox}>ביטול</Button>
              <Button variant="contained" color="success" size="small" onClick={action}>אישור</Button>
            </div>:
            <div className="me-3 mb-2">            
              <Button variant="contained"  color="error"  size="small" onClick={closeMsgBox}>סגור</Button>              
            </div>
          }
        </DialogActions>
      </Dialog> 
    </div>        
  )  
}  
export default MsgBox;  
