import React, {useState,useEffect} from "react";
import PageTitle from "../Components/page_title";
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import SearchByDates from "../Components/search_by_date";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import SchTchCourseBox from '../Components/sch_tch_course_box';
import axios from 'axios';
import ReactLoading from 'react-loading';
import CourseModal from "../Components/course_modal";
import Divider from '@mui/material/Divider';

const baseUrl = process.env.REACT_APP_BASE_URL; 
const win=4;

const SchTchCourses = (props) => {
  const [page, setPage] = useState(1);
  const [schedule,setSchedule]=useState(false);
  const [courses,setCourses]=useState([]);
  const [refresh,setRefresh]=useState(false);
  const [position,setPosition]=useState("ACTIVE")
  const [screenHeight, setScreenHeight] = useState(0);
  const [fire,setFire]=useState(false);

  useEffect(() => {
    setScreenHeight(window.innerHeight-200);
  }, []);
//-------------------------------------------------------------------------------------------
  
  
  useEffect(()=>{

    const fetchCourses = async () => {
      try {
        setFire(true)
        if(props.user==='SCH')
          var response = await axios.get(`${baseUrl}/sch_courses.php`,{
            params: {                        
              userId:props.userId,
              filter:position                                                                      
            }
          })
        else if(props.user==='TCH')
          var response = await axios.get(`${baseUrl}/tch_courses.php`,{
            params: {                        
              userId:props.userId,
              filter:position                                                                      
            }
          })
        
        if(Array.isArray(response.data) && response.data.length){
          setCourses(response.data)  
          setSchedule(prev=>prev=!prev)      
        }
        else{
          setCourses([])         
        }  
        setFire(false)
      } 
      catch (error) {

        alert(error) ;
        
      }

      setPage(1)
      goToTop()
      
    }

    if(refresh || position!=="UNDEFINED" )
      fetchCourses();
        
  },[refresh,position]);
//-------------------------------------------------------------------------------------------

  const fetchCoursesByDates = async (sDate,eDate) => {
    try {

      setFire(true)      
      if(props.user==='SCH')
        var response = await axios.get(`${baseUrl}/sch_courses_by_dates.php`,{
          params: {                        
            userId:props.userId, 
            sDate:sDate,
            eDate:eDate,                                                     
          }
        })
      else if(props.user==='TCH')
        var response = await axios.get(`${baseUrl}/tch_courses_by_dates.php`,{
          params: {                        
            userId:props.userId, 
            sDate:sDate,
            eDate:eDate,                                                         
          }
        })
        
      if(Array.isArray(response.data) && response.data.length){
        setCourses(response.data)
        setSchedule(prev=>prev=!prev)
      }
      else{
        setCourses([])
      }
      setPosition("UNDEFINED")  
      setFire(false)
    } 
    catch (error) {

      alert(error) ;
      
    }

    setPage(1)
    goToTop()
    
  }
//-------------------------------------------------------------------------------------------

  const goToTop = () => {
    window.scrollTo({
        top:0,
        behavior: 'auto',
    })
  }
//-------------------------------------------------------------------------------------------
  
  const refreshPage = () => {
    setRefresh(prev=>prev=!prev)
  }
//-------------------------------------------------------------------------------------------

  const handleChangePage = (_,page)=> {
        
    if (page !== null){
      setPage(page);
      setSchedule(prev=>prev=!prev)
      goToTop()
    }
  }
//-------------------------------------------------------------------------------------------

  const updateIcon=(file,index)=>{

    let x=(page-1)*win+index;
    let temp_state = [...courses];
    let temp_element = { ...temp_state[x]};
    temp_element.photo = file;
    temp_state[x] = temp_element;
    setCourses(temp_state);
  }
//-------------------------------------------------------------------------------------------
  const updateCourseDtls=(subject,teacher,date,index)=>{
    let x=(page-1)*win+index;
    let temp_state = [...courses];
    let temp_element = { ...temp_state[x]};
    temp_element.subject = subject;
    temp_element.teacher = teacher;
    temp_element.date = date;
    temp_state[x] = temp_element;
    setCourses(temp_state);
  }
//-------------------------------------------------------------------------------------------

  const updateStatus=(status,index)=>{

    let x=(page-1)*win+index;
    let temp_state = [...courses];
    let temp_element = { ...temp_state[x]};
    temp_element.status = status;
    temp_state[x] = temp_element;
    setCourses(temp_state);
  }
//-------------------------------------------------------------------------------------------

  const updateSyllabus=(syllabus,index)=>{

    let x=(page-1)*win+index;
    let temp_state = [...courses];
    let temp_element = { ...temp_state[x]};
    temp_element.syllabus = syllabus;
    temp_state[x] = temp_element;
    setCourses(temp_state);
  }
//-------------------------------------------------------------------------------------------

  const removeCourseFromList=(code)=>{

    const newList=courses.filter((item)=>item.code!==code);

    setCourses(newList);

  }
//-------------------------------------------------------------------------------------------

  return (
    
    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>
                      
        <div className="mt-4">
          <PageTitle text="קורסים שלי"/>
        </div>
                
        <div className="mt-5 ms-2">
          <SearchByDates setDateRange={fetchCoursesByDates}/>
        </div>
                
        <div className="mt-4">          
          <Divider></Divider>
        </div> 
        
        <div className="d-flex gap-3 mt-4">
          <Button
            variant="contained"
            color="secondary"
            size="small"
            disabled={position === "ACTIVE"}
            onClick={() => setPosition("ACTIVE")}
          >
            קורסים פעילים
          </Button>

          <Button
            variant="contained"
            color="secondary"
            size="small"
            disabled={position === "ARCHIVED"}
            onClick={() => setPosition("ARCHIVED")}
          >
            ארכיון
          </Button>
          
          {position === "ACTIVE" && (
            <CourseModal
              link={true}
              user={props.user}
              makerId={props.userId}
              refreshPage={refreshPage}
              enabled={props.enabled}
              editing={false}
            />
          )}
        </div>

        <div className="d-flex flex-column position-relative gap-3 mt-2">

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x" style={{zIndex: "1",top:"300",left:'300'}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          }
 
          {courses.slice((page-1)*win,(page-1)*win+win).map((c,index)=>(                                                                                               
            <div>
              <SchTchCourseBox 
                index={index}
                bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"} 
                teacher={c.teacher} 
                rating={c.gcode!==null?c.rating:c.prating} 
                ratings={c.gcode!==null?c.counter:c.pcounter}
                subject={c.subject}
                price={c.price} 
                date={c.date} 
                photo={c.photo} 
                private={c.mode==="PRIVATE"?true:false}
                code={c.code}
                xcode={c.gcode!==null?c.gcode:c.pcode}
                status={c.status}               
                user={props.user}
                makerId={props.userId}
                sch_maker={c.sch_maker}
                tchId={c.tchId}
                syllabus={c.syllabus}
                updateIcon={updateIcon}
                updateCourseDtls={updateCourseDtls}
                schedule={schedule}
                removeCourseFromList={removeCourseFromList} 
                updateStatus={updateStatus}
                updateSyllabus={updateSyllabus}
                position={position}               
              />             
            </div>
          ))}
                             
        </div>
        
        <div  class="d-flex justify-content-end mt-4" dir="ltr">                     
          <Pagination count={Math.ceil(courses.length/win)} variant="outlined" shape="rounded" color="primary" page={page} onChange={handleChangePage}/>         
        </div>

    </div>
    
  );
};

export default SchTchCourses;
