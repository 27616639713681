import FacebookLogin from "react-facebook-login";
import { login, signup } from "./db";
import { SiFacebook } from "react-icons/si";
import './social_btns.css';

const FacebookBtn = (props) => {

  const setItemWithExpiration = (key,data) => {
    const now = new Date()
    
    const currentTime= now.getTime();

    const item = {
      data: data,
      startActivity: currentTime, 
      lastActivity : currentTime,
    }
    
    window.localStorage.setItem(key, JSON.stringify(item));
  }
//-------------------------------------------------------------------------------------------

  const responseFacebook = async(response) => {

    if(response.status==='unknown')
      return;

    try {

      props.turnFireOn();

      const facebookId = response.userID;
      const name       = response.name;
      const email      = response.email;
      const imageUrl   = response.picture.data.url
      const auth       = "FACEBOOK";
      const user=props.user;

      const [f_name, ...l_nameArray] = name.split(" ");
      const l_name = l_nameArray.join(" ");

      let authResponse;

      if (props.mode === 'login') {
        authResponse = await login(user, auth, email, facebookId);
      } else {
        authResponse = await signup(user, auth, email, facebookId, f_name, l_name);
      }

      const [id, userId, enabled, blocked] = authResponse;

      if (id > 0 && userId > 0) {

        const loginData = {
          isLoggedIn: true,
          userID: userId,
          id: id,
          name: name,
          email: email,
          picture: imageUrl,
          auth: auth,
          enabled: enabled,
          blocked: blocked,
          user: user,
        };

        setItemWithExpiration('loginData', loginData);
        props.connect();
      } else {
        throw new Error("Login failed. Please try again.");
      }
      
    } catch (error) {
      alert(error.message);
    } finally {
      props.turnFireOff();
    }
  }
//------------------------------------------------------------------------------------------- 
  
  return(
    <>
      <FacebookLogin
        cssClass="fb-login-button social-btn"
        textButton={
                      <div className="d-flex align-items-center gap-3 ps-2">
                        <span>
                          <SiFacebook className="icon"/>
                        </span>
                        <span>התחבר באמצעות פייסבוק</span>                       
                      </div>
                    }        
        appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}      
        fields="name,email,picture"
        callback={responseFacebook}  
        isDisabled={props.disabled}          
      />
    </>    
  )};
  
  export default FacebookBtn;
