import React, {useState,useEffect} from "react";
import ContactForm from '../Components/contact_form '
import PageTitle from '../Components/page_title';
import '../Pages/contact_form.css'

const Contact = () => {
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    setScreenHeight(window.innerHeight-200);
  }, []); 
//-------------------------------------------------------------------------------------------
  
  return(
     
      <div className='d-flex flex-column align-items-center mb-5' style={{ minHeight: `${screenHeight}px` }}>                  
        <div className='msg-wrap'>
            <div className="mt-4">
              <PageTitle text="צור קשר"/>
            </div >
            <div  className="msg-form mt-4">
              <ContactForm/>
            </div>
        </div>          
      </div>             
  )};
  
  export default Contact;
