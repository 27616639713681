
import React, { useState,useEffect } from "react";
import BasicRating  from "./rating";
import Syllabus from "./syllabus"
import CourseSchedule from "./course_schedule";
import '../Components/course_box.scss'
import {useNavigate} from 'react-router-dom';
import noIcon from '../Assets/Images/math3.png';
import ShareButton from "./share_buttons";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const GrpCourseBox = (props) =>{
  
  const [flag, setFlag] = useState(false);
  const navigate = useNavigate();
  const [status,setStatus]=useState(props.status);
  const shareText = `🚀 הזדמנות שלא מפספסים! 🌐 קורס אונליין בנושא ${props.subject} 📚 עם ${props.teacher}, אחד המורים המובילים בתחום. 💡 תן לעצמך את הכלים להצלחה והרשם עכשיו! 🔑`;
  const courseLink = `🔗 לחץ כאן להרשמה: https://www.doroosonline.co.il/search?code=${props.code}&mode=GROUP`;

  useEffect(()=>{
       
    setStatus(props.status)
    setFlag(false) 

  },[props.status])  
//-------------------------------------------------------------------------------------------

  useEffect(()=>{

    setFlag(false)
                
  },[props.schedule]);
//-------------------------------------------------------------------------------------------

  const courseRegistration= async ()=>{
        
    if(props.user==="DEFAULT"){
      navigate('/login',{state:{code:props.code,mode:'GROUP'}})
    }
    else {
      try {
            const response = await axios.get(`${baseUrl}/course_reg.php`,{
              params: {                        
                code:props.code,
                stdId:props.stdId                                             
              }
            })

            if(response.data.status==='success'){
              alert(response.data.message)
              props.updateCourseStatus(props.index,props.code)
            }
            else{
              throw new Error(response.data.message || 'An unknown error occurred');
            }

      } 
      catch (error) {

        alert(error.message) ;
        
      }
    }
  }
//-------------------------------------------------------------------------------------------
  const toClassRoom = () => {
    if(props.user==="TCH" || props.user==="SCH" ) {
      navigate('/tchclassroom',{state:{code:props.code,xcode:props.xcode,user:props.user,price:props.price,status:status,mode:'GROUP',subject:props.subject}})        
    }
    else if(props.user==="STD")
      navigate('/stdclassroom',{state:{code:props.code,xcode:props.xcode,user:props.user,mode:'GROUP',subject:props.subject}})    
  }
//------------------------------------------------------------------------------------------- 
  
  const ToggleProgram = () => {
    setFlag((f)=>!f)
  };
//------------------------------------------------------------------------------------------- 
  return (

    <div className="course-container" style={{background:props.bgcolor}}>
      <div className="course-box">
            
        <div className="subject-icon-container">
          <div className="frame">
              <img 
                  src={props.photo != null ? props.photo : noIcon} 
                  alt="subject icon" 
                  className="subject-icon" 
              />
          </div>
        </div>

        <div className="syllabus-container">
          <div className="syllabus-subject">
            <p className="subject-title">{props.subject}</p>
            <Syllabus className="syllabus-link" btn_txt="לפרטים מלאים" syllabus={props.syllabus}/>        
          </div>
        </div>

        <div className="start-date">
          <p className="s-date">תאריך התחלה</p>
          <p className="date">[ {props.date} ]</p>
        </div>

        <div className="rating-container">
          <div className="teacher-rating">
            <p className="teacher-name">{props.teacher}</p>          
              <span dir="ltr">
                <BasicRating 
                  value={props.rating} 
                  ratings={props.ratings} 
                  readOnly={true} 
                  status={status} 
                />
              </span>           
          </div>
        </div>

        <div>                 
          <button 
            type="button" 
            className="btn btn-warning btn-sm" 
            style={{borderRadius: "20px" }} 
            onClick={ToggleProgram}
          >
            מערכת שעות
          </button> 
        </div>

        <div>
          {props.search?
            <>
              {props.reg!=null?
                <div>
                    <button 
                      type="button" 
                      className="btn btn-primary btn-sm" 
                      style={{width:"70px", borderRadius: "20px" }} 
                      disabled
                    >
                      רשום
                    </button> 
                </div>
                :
                <div>
                    <button 
                      type="button" 
                      className="btn btn-success btn-sm" 
                      style={{width:"70px", borderRadius: "20px" }} 
                      onClick={courseRegistration}
                    >
                      הרשמה
                    </button> 
                </div> 
              }
            </>
            :              
            <div> 
                {status!=="EDITING"?
                  <button 
                    type="button" 
                    className="btn btn-success btn-sm" 
                    style={{width:"70px", borderRadius: "20px" }} 
                    onClick={toClassRoom}
                  >
                    מפגשים
                  </button> 
                  :
                  <button 
                    type="button" 
                    className="btn btn-success btn-sm" 
                    style={{width:"70px", borderRadius: "20px" }} 
                    disabled
                  >
                    מפגשים
                  </button> 
                }    
            </div>
          }
        </div>

      </div>
      
      {flag?                                  
        <div className="d-flex justify-content-center">              
          <div style={{width:"90%"}}>  
            <CourseSchedule code={props.xcode} refresh={props.refresh}/>
          </div>
        </div>                                                           
        :
        null
      }

      <div className="position-absolute" style={{top:"-4px",left:"-2px"}}>  
        {props.children}
      </div>

      <div className="position-absolute" style={{ [props.user === 'STD'||props.search ? 'top' : 'bottom']: 1, left: 3 }}>
        <div className="d-flex flex-column align-items-center">
          <ShareButton courseLink={courseLink} shareText={shareText}/>
          <PeopleOutlineIcon style={{ color: '#9e9e9e' }} />          
        </div>
      </div>

    </div>
                                       
  );
};
export default GrpCourseBox;


