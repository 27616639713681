
import React, { useState } from "react";
import { FcApproval } from "react-icons/fc";
import { MdCancel } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa";
import DocViewer from "./docs_viewer";
import AcceptBtn from "./accept_btn";
import '../Components/box.css'
import RejectBtn from "./reject_btn";

const DocumentConBox = (props) =>{
  const [status,setStatus]=useState(props.status);
  
  const confirm= async (comment,expDate) => {

    try{

      await props.updateDocStatus(props.userId,props.docId,props.user,comment,expDate,'APPROVED')
           
      if(status==="NO_RESPONSE")
        props.decreaseDocsNum() 
        
      setStatus("APPROVED") 

    } catch (error) {
        
       alert("Error: " + error.message);  
    }                                      
             
  }
//-------------------------------------------------------------------------------------------
  
  const deny= async (comment,expDate) => {

    try{

      await props.updateDocStatus(props.userId,props.docId,props.user,comment,expDate,'NOT_APPROVED')
           
      if(status==="NO_RESPONSE")
        props.decreaseDocsNum() 
        
      setStatus("NOT_APPROVED") 

    } catch (error) {
        
       alert("Error: " + error.message);  
    }                       
        
  }
//-------------------------------------------------------------------------------------------

  return (
           
    <div class="item-box" style={{background:props.bgcolor}}>                                                      
      <div className="d-flex flex-wrap align-items-center justify-content-around" > 
                          
        <div class="px-3 py-3 py-lg-2" style={{width:"100px"}}>
          <span>{props.index}</span>                                                         
        </div>
        
        <div class="px-3 py-3 py-lg-1" style={{width:"150px"}}>
           <span><DocViewer btn_txt={props.docType} doc={props.file}/></span>                                                                  
        </div>
        
        <div class="px-3 py-3 py-lg-2" style={{width:"150px"}}>
          <span>{props.date}</span>
        </div>

        <div class="px-3 py-3 py-lg-2" style={{width:"150px"}}>
           <span>{props.userName}</span>                                                                  
        </div>

        <div className="d-flex gap-4 px-3 py-4 py-lg-3">
          
          <AcceptBtn status={status} confirm={confirm} exp={true} title="אישור מסמך" />
          <RejectBtn status={status} deny={deny} title="סירוב מסמך" />         
          <span>{status==='NO_RESPONSE'?<FaRegEyeSlash color="red" size={30}/>:status==="APPROVED"?<FcApproval size={30}/>:<MdCancel color="red" size={30}/>}</span>           

        </div>                
      </div>                                                  
    </div>                                
  );
};



export default DocumentConBox;
