import React, { useState,useEffect } from "react";
import { Modal} from 'react-bootstrap'; 
import Button from '@mui/material/Button';
import { VscFiles } from "react-icons/vsc"
import SelectPdfFile from "./select_pdf_file";
import ReactLoading from 'react-loading';
import { storage } from "./firebase";
import { ref,uploadBytes,getDownloadURL,deleteObject} from "firebase/storage";
import { v4 } from 'uuid';
import {updateCourseSyllabus} from "./db"

const AddSyllabus=(props)=>{ 
 
  const [show,setShow]=useState(false);
  const [fire,setFire]=useState(false);
  const [file,setFile]=useState(null);
  const [url,setUrl]=useState("");

  useEffect(()=>{
    if(props.syllabus!==""){
      const fileRef=ref(storage,`syllabuses/${props.syllabus}`)
      getDownloadURL(fileRef).then((url)=>{
        setUrl(url)
        setFire(true)
      }).catch((error)=>{
        alert(error)     
      })            
    }

    if(!props.link)
      setShow(true);  

  },[]);
//-------------------------------------------------------------------------------------------

  const stopLoding=()=>{
    setFire(false)
  }
//-------------------------------------------------------------------------------------------

  const closeSyllabusBox = () => {         
    setShow(false) 
    if(!props.link)
    props.resetMenu(0);    
  }
//-------------------------------------------------------------------------------------------

  const openSyllabusBox = () => {         
    setShow(true)     
  }
//-------------------------------------------------------------------------------------------
  const pdfFileUpload = () => {
    if( file!=null ){
        
      setFire(true)
      const newFile = v4() + ".pdf"
      const fileRef=ref(storage,`syllabuses/${newFile}`)

      uploadBytes(fileRef,file).then(async ()=>{                                               
        const code = await updateCourseSyllabus(props.code,newFile)
  
        if(code <= 0){                                  
          alert("אופס, פעולת עדכון הסילבוס לא הושלמה בהצלחה!!")
        }
        else if(props.syllabus!==""){
          const file_Ref=ref(storage,`syllabuses/${props.syllabus}`)
          
          deleteObject(file_Ref).then(()=>{ 
            
          }).catch((error)=>{
            alert(error)                   
          })
        }

      }).then(()=>{                                              
        document.getElementById('file').value= null; 
        setFile(null)
        setFire(false) 
      }).then(()=>{
        alert("תהליך הוספת סילבוס לקורס הושלם בהצלחה!!") 
        props.updateSyllabus(newFile,props.index)
        closeSyllabusBox()            
      }).catch((error)=>{ 
        alert(error)
        setFire(false) 
      });
    }                                      
  }
//-------------------------------------------------------------------------------------------      

  const selectPdf=(pdf_file)=>{
    setUrl(URL.createObjectURL(pdf_file))
    setFile(pdf_file) 
    setFire(true)  
  }
//-------------------------------------------------------------------------------------------      
  return (  
      <div>                
        {props.link?<a href="#"  onClick={openSyllabusBox}>{props.btn_txt}</a>:null}
        <Modal 
          show={show} 
          onHide={closeSyllabusBox} 
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"             
        >  
          
          <Modal.Header closeButton style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
            <div className="d-flex gap-2 align-items-center">
                <div><VscFiles size={30} /></div>
                <div className="mt-1"><h5>הוספה/עדכון תוכנית לימודים</h5></div>
            </div>                         
          </Modal.Header>
         
          <Modal.Body>    
            <div className="mt-4">
              <SelectPdfFile selectPdf={selectPdf}/>              
              <div className="position-relative mt-3" style={{height:"300px",borderColor:"lightgray", borderStyle:"solid",borderWidth:"thin"}}>
                {fire?
                  <div className="position-absolute top-50 start-50 translate-middle-x" style={{zIndex: "1",top:"300",left:'300'}}>
                      <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
                  </div>
                  :
                  null
                }

              <embed 
                type="application/pdf" 
                src={url} 
                width={100+'%'} 
                height={100+'%'} 
                onLoad={()=>{setTimeout(()=>{setFire(false) },1000)}}
              />

                                
              </div>
            </div>              
          </Modal.Body>
            
          <Modal.Footer>                           
            <div className="d-flex gap-3 me-2 mb-2">            
              <Button variant="contained"  color="error"  size="small" onClick={closeSyllabusBox}>ביטול</Button>
              {file!=null?
                <Button variant="contained" color="success" size="small" onClick={pdfFileUpload}>העלאה</Button>
                :
                <Button variant="contained" color="success" size="small" disabled>העלאה</Button>
              }
            </div>
          </Modal.Footer> 
        </Modal> 
      </div>        
    )  
  }   
export default AddSyllabus;  
