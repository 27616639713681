import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, TelegramShareButton, LinkedinShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon, WhatsappIcon, TelegramIcon, LinkedinIcon } from 'react-share';
import { IconButton, Box } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';

const ShareButton = ({ courseLink,shareText }) => {

  const [anchorEl, setAnchorEl] = useState(null);

  const handleShareButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>

      <IconButton onClick={handleShareButtonClick}>
        <ShareIcon fontSize='small' />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{ minWidth: 'auto' }}
      >
        <Box display="flex">
          <MenuItem onClick={handleMenuClose}>
            <FacebookShareButton url={courseLink} quote={shareText}>
              <IconButton>
                <FacebookIcon size={32} round={true} />
              </IconButton>
            </FacebookShareButton>
          </MenuItem>

          <MenuItem onClick={handleMenuClose}>
            <TwitterShareButton url={courseLink} title={shareText}>
              <IconButton>
                <TwitterIcon size={32} round={true} />
              </IconButton>
            </TwitterShareButton>
          </MenuItem>

          <MenuItem onClick={handleMenuClose}>
            <LinkedinShareButton url={courseLink} title={shareText}>
              <IconButton>
                <LinkedinIcon size={32} round={true} />
              </IconButton>
            </LinkedinShareButton>
          </MenuItem>

          <MenuItem onClick={handleMenuClose}>
            <WhatsappShareButton url={courseLink} title={shareText}>
              <IconButton>
                <WhatsappIcon size={32} round={true} />
              </IconButton>
            </WhatsappShareButton>
          </MenuItem>

          <MenuItem onClick={handleMenuClose}>
            <TelegramShareButton url={courseLink} title={shareText}>
              <IconButton>
                <TelegramIcon size={32} round={true} />
              </IconButton>
            </TelegramShareButton>
          </MenuItem>
        </Box>
      </Menu>
    </div>
  );
};

export default ShareButton;
