import { useState, useEffect } from 'react';
import axios from 'axios';

const useCurrencyRate = (targetCurrency) => {
  const [rate, setRate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_KEY = 'ee815b8fd4398cb9143af2f1'; // Your API Key here
  const apiUrl = `https://v6.exchangerate-api.com/v6/${API_KEY}/latest/USD`;

  useEffect(() => {
    const fetchCurrencyRate = async () => {
      try {
        const response = await axios.get(apiUrl);
        if (response.data.result === 'success') {
          const conversionRates = response.data.conversion_rates;
          const specificRate = conversionRates[targetCurrency];
          setRate(specificRate);
        } else {
          throw new Error('Failed to fetch currency rate');
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchCurrencyRate();
    
  }, [targetCurrency]);

  return { rate, isLoading, error };
};

export default useCurrencyRate;

