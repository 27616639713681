    export const addMinutes = (time,minutes)=>{
        const times= time.split(':');
        let hh=parseInt(times[0]);
        let mm=parseInt(times[1]);
        let m;
        m= mm + minutes;
        mm= m % 60;
        hh= hh + Math.floor(m/60);
        return(hh+":"+mm)
    }
//-------------------------------------------------------------------------------
    export const dateTimeToMs = (date,time) =>{
            
        const d = new Date(date+' '+time);
        return(d.getTime());
    }
//-------------------------------------------------------------------------------
    export const dateTimeToMs2 = (date) =>{
            
        const d = new Date(date);
        return(d.getTime());
        
    }
//-------------------------------------------------------------------------------
    export const addDays = (date, days) =>{

        date.setDate(date.getDate() + days);
        return date;
    }

