import  React, {useState,useEffect,useRef} from "react";
import ProfileStatus from "../Components/profile_status";
import PageTitle from "../Components/page_title";
import ChangePassword from "../Components/change_password";
import AccountDtls from '../Components/account_dtls';
import SchSettings from "../Components/sch_settings";

const SchProfile = (props) => {
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    setScreenHeight(window.innerHeight-200);
  }, []);
//-------------------------------------------------------------------------------------------

  return(
    <div className="container mb-5" style={{ minHeight: `${screenHeight}px` }}>

      <div className="mt-4">
        <PageTitle text="פרופיל"/>
      </div>

      <div className="profile mt-4">
          
          <div className="profile-img">
            <ProfileStatus userId={props.id}/>
          </div>

          <div className="profile-dtls">         
            <AccountDtls userId={props.id}/>
          </div>

          <div className="profile-settings">

            {props.auth==='NORMAL'?
              <div className="password">
                <ChangePassword userId={props.id}/>
              </div>
              :
              null
            }

            <div className="job">
              <SchSettings userId={props.userId} user={props.user}/>
            </div>

          </div>

      </div>

    </div>
  )
};
  
export default SchProfile;
