import React, { useState } from "react";
import '../Components/profile.css';
import * as Yup from 'yup';
import '../Components/forms.css' 
import { passwordRecovery } from "./db";

const loginSchema = Yup.object().shape({

  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),        
});


const ForgotPassword=(props)=> { 
  const [errors, setErrors] = useState({});
  const [userDtls, setUserDtls] = useState({  
    email:''
  });
//-------------------------------------------------------------------------------------------

  const handleChange = e => {
      const { name, value } = e.target;
      setUserDtls(prevState => ({
          ...prevState,
          [name]: value
      }));
  }
//-------------------------------------------------------------------------------------------
              
  const handleSubmit = async (event) => {
    event.preventDefault();

    props.turnFireOn()  

    try {
          await loginSchema.validate(userDtls, { abortEarly: false });
                
          const email    = userDtls.email;      
          const user=props.user;

          const data= await passwordRecovery(user,email);
        
          alert(data.message)
                              
          setUserDtls({email:''});
          setErrors({});

    }catch (err) {
      
      setErrors(err.inner.reduce((acc, { path, message }) => {
        acc[path] = message;
        return acc;
      }, {}));
    } finally {
      props.turnFireOff()
    }
  }
//-------------------------------------------------------------------------------------------
  
  return (
    
    <form onSubmit={handleSubmit}>
      <div className="d-flex flex-column gap-4 mt-4">  

        <div  className="label-100-wrap">                
          <label className="form-label label-100" for="email">דואר אלקטרוני</label>
          <div>
            <input  
              type="text" 
              className={`form-control ${errors.email? 'invalid' : ''}`} 
              name="email" 
              value={userDtls.email} 
              onChange={handleChange} 
              placeholder="דואר אלקטרוני"
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </div>   
        </div>

        <div>
          <button 
            type="submit" 
            className="btn btn-outline-secondary btn-sm" 
            style={{width: "100%", height: "40px"}} 
            disabled={props.disabled}
          >
            שחזר סיסמה
          </button>
        </div>
                                                                                                                                                                             
      </div> 
    </form>     
  );
}

export default ForgotPassword;
