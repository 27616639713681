
import  React, {useState,useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import HomeCarousel from "../Components/carousel";
import SuggestedCourses from "../Components/suggested_courses";
import SuggestedTeachers from "../Components/suggested_teachers";
import FollowingTeachers from "../Components/following_teachers";

const Home = (props) => {
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    setScreenHeight(window.innerHeight-200);
  }, []);
//-------------------------------------------------------------------------------------------
  
  return (
    <>  

      <div className="mt-0">
        <HomeCarousel/>
      </div>

      <div className="container" style={{ minHeight: `${screenHeight}px` }}>
                                                    
        <div>
          <SuggestedCourses user={props.user} userId={props.userId}/>
        </div>
                                        
        <div>
          <SuggestedTeachers user={props.user} userId={props.userId}/> 
        </div>

        { props.user==="STD"?                      
            <div className="mb-5">
              <FollowingTeachers user={props.user} userId={props.userId}/>
            </div>
            :
            <div className="mb-5"></div>
        }  
                                                                      
      </div>

    </>      
  );
};

export default Home;

