import  React, {useState,useEffect,useRef} from "react";
import PageTitle from "../Components/page_title";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import Divider from '../Components/divider'
import ReportSummary from "../Components/report_summary";
import ReportByMonth from "../Components/report_by_month";
import axios from 'axios';
import { addMinutes } from "../Components/time";
import InvoiceLine from "../Components/invoce_line";
import ReactLoading from 'react-loading';

const baseUrl = process.env.REACT_APP_BASE_URL; 

const TchInvoices = (props) => {

  const [invoceItems,setInvoceItems]=useState([]);
  const [refresh,setRefresh]=useState(false);   
  const total = useRef(0);
  const [screenHeight, setScreenHeight] = useState(0);
  const [fire,setFire]=useState(false);

  useEffect(() => {
    setScreenHeight(window.innerHeight-200);
  }, []);
//-------------------------------------------------------------------------------------------
                 
  const  setDateRange = async (sDate,eDate) => {
    
    total.current=0

    try {
      
      setFire(true)
      const response = await axios.get(`${baseUrl}/tch_lessons_report.php`,{
        params: {                        
          userId:props.userId, 
          sDate:sDate,
          eDate:eDate                                                                
        }
    })
              
      if(Array.isArray(response.data) && response.data.length){
        setInvoceItems(response.data)
        setFire(false)
      }
      else{
        setInvoceItems([])
        setFire(false)
      }
        
    } 
    catch (error) {

      alert(error) ;
      
    }

    setRefresh(prev=>!prev)
    
  }     
//-------------------------------------------------------------------------------------------
  
  const updateTotal =(price) =>  {
    
    total.current=total.current+price      
  }
//-------------------------------------------------------------------------------------------

  return (

    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>
                      
        <div className="mt-4">
          <PageTitle text="דוח שעות"/>
        </div>
        
        <div className="mt-5 ms-2">
          <ReportByMonth setDateRange={setDateRange}/>
        </div>
                 
        <div className="mt-4">
          <Divider height="2px" color="rgb(255, 0, 0,0.2)"/> 
        </div>

        <div className="position-relative">

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex:"1"}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          } 

          {invoceItems.map((item,index)=>(                                                                                                          
            <div className={index===0?"mt-4":"mt-3"}>
              <InvoiceLine
                index={index}
                bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"} 
                teacher={item.lessonId!==null?item.teacher:item.p_teacher}                                
                subject={item.lessonId!==null?item.subject:item.p_subject} 
                date={item.lessonId!==null?item.date:item.p_date}
                start={item.lessonId!==null?item.start:item.p_start} 
                end={item.lessonId!==null?addMinutes(item.start,item.num*50):addMinutes(item.p_start,item.p_num*50)}                
                photo={item.photo}                               
                price={item.lessonId!==null?item.price:item.p_price}
                amount={item.lessonId!==null?item.num:item.p_num}
                updateTotal={updateTotal}                                                                                                                                                                                   
              />              
            </div>            
          ))}
        </div>
      
      {invoceItems.length>0?
        <>                                                                                                              
          <div className="mt-4">
            <Divider height="2px" color="rgb(255, 0, 0,0.2)"/>
          </div>
        
          <div className="row mt-4 gap-3 gap-lg-0">
                                            
            <div className="col-lg order-0 order-lg-1">

              <div> 
                <ReportSummary total={total.current}/> 
              </div>
              
            </div> 

          </div>
        </>
        :
        null
      } 
  
    </div>
    
  );
};

export default TchInvoices;


