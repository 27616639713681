
import React, { useState,useEffect } from "react";
import BasicRating  from "./rating";
import Syllabus from "./syllabus"
import '../Components/course_card.scss'
import noIcon from '../Assets/Images/math3.png'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ShareButton from "./share_buttons";
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;
 
const CourseCard = (props) =>{ 

  const shareText = `🚀 הזדמנות שלא מפספסים! 🌐 קורס אונליין בנושא ${props.subject} 📚 עם ${props.teacher}, אחד המורים המובילים בתחום. 💡 תן לעצמך את הכלים להצלחה והרשם עכשיו! 🔑`;
  const courseLink = `🔗 לחץ כאן להרשמה: https://www.doroosonline.co.il/search?code=${props.code}&mode=${props.mode}`;

  const courseRegistration = async () => {
        
      try {

        const response = await axios.get(`${baseUrl}/course_reg.php`,{
          params: {                        
            code:props.code,
            stdId:props.userId                                             
          }
        })

        if(response.data.status==='success'){
          alert(response.data.message)
          props.updateCourseStatus(props.index,props.code)
        }
        else{
          throw new Error(response.data.message || 'An unknown error occurred');
        }

      } catch (error) {
        alert(error.message) ;
      }
  }
//-------------------------------------------------------------------------------------------
    
  return (  
    <div className="card-container" style={{background:props.bgcolor}}>
      <div className="course-box">

        <div className="subject-icon-container">
          <div className="frame">
              <img 
                  src={props.photo != null ? props.photo : noIcon} 
                  alt="subject icon" 
                  className="subject-icon" 
              />
          </div>
        </div>
          
        <div className="syllabus-container">
          <div className="syllabus-subject">
            <p className="subject-title">{props.subject}</p>
            <Syllabus btn_txt="לפרטים מלאים" syllabus={props.syllabus}/>        
          </div>
        </div>

        <div className="start-date">
          <p className="s-date">תאריך התחלה</p>
          <p className="date">[ {props.date} ]</p>
        </div>

        <div className="rating-container">
          <div className="teacher-rating">
            <p className="teacher-name">{props.teacher}</p>          
              <span dir="ltr">
                <BasicRating 
                  value={props.rating} 
                  ratings={props.ratings} 
                  readOnly={true} 
                />
              </span>           
          </div>
        </div>

        { props.user==="STD"?     
          <>
            { props.reg!=null?
                <button 
                  type="button" 
                  className="btn btn-success btn-sm reg-btn" 
                  style={{width:"80%"}} 
                  disabled
                >
                  רשום
                </button>           
              :          
                <button 
                  type="button" 
                  className="btn btn-success btn-sm reg-btn"  
                  onClick={courseRegistration}
                >
                  הרשמה
                </button>           
            }
          </>                  
          :
          null        
        }        
      </div>
    
      <div className="position-absolute" style={{ top : 3, left: 3 }}>
        <div className="d-flex flex-column align-items-center gap-3">
          <ShareButton courseLink={courseLink} shareText={shareText}/>
          { props.mode==='GROUP'?
            <PeopleOutlineIcon style={{ color: '#9e9e9e' }} /> 
            :
            <PermIdentityIcon style={{ color: '#9e9e9e' }} />
          }         
        </div>
      </div>

    </div>                                                                                                                                                                                            
  );
};
export default CourseCard;



