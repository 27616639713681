import React, { useState } from "react";
import '../Components/search_by_date.css'

const ReportByMonth=(props)=>{ 
   
    const months=["01","02","03","04","05","06","07","08","09","10","11","12"]   
    const years=["2020","2021","2022","2023","2024","2025","2026","2027","2028","2029","2030"]
    
    const [month, setMonth] = useState(0);
    const [year, setYear] = useState(0);

    const setDateRange = () => {
              
        const yyyy=parseInt(year) + 2020;
        const mm=parseInt(month) + 1;
        const dd=new Date(yyyy,mm,0).getDate();
        const startDate=years[year]+"-"+months[month]+"-01";
        const endDate=years[year]+"-"+months[month]+"-"+dd.toString();    
        props.setDateRange(startDate,endDate);     
    }
//-------------------------------------------------------------------------------------------
        
    return (  
                
        <div className="d-flex flex-wrap gap-4 align-items-center"> 
            
            <div className="label-100-wrap">
                <label className="form-label label-100" for="year">שנה</label>
                <div>
                    <select class="form-select" id="year" name="year" style={{width:"100px"}} value={year} onChange={e => setYear(e.target.value)}>
                    {
                        years.map((y,index)=>(
                            <option value={index}>{y}</option>
                        ))
                    }                                         
                    </select> 
                </div>                
            </div>

            <div className="label-100-wrap">
                <label className="form-label label-100" for="month">חודש</label>
                <div>
                    <select class="form-select" id="year" name="year" style={{width:"100px"}} value={month} onChange={e => setMonth(e.target.value)}>
                    {
                        months.map((m,index)=>(
                            <option value={index}>{m}</option>
                        ))
                    }                                         
                    </select> 
                </div>
            </div>

            <div>            
                <button class="btn btn-primary btn-sm" style={{borderRadius:"20px",width:"70px"}} onClick={setDateRange}>הצג</button>
            </div>                                                                              
            
        </div> 
                          
    )  
  } 
  
export default ReportByMonth 
